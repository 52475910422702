import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import Body from "../typography/Body"
import Paragraph from "../typography/Paragraph"
import styled from "@emotion/styled"

type ParagraphSectionProps = BoxProps & {
  heading: string
}

const Wrapper = styled(Paragraph)`
  * + * {
    margin-bottom: 0.5rem;
  }
  .ql-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export default function ParagraphSection({
  heading,
  children,
  ...props
}: ParagraphSectionProps) {
  return (
    <Box mb="5rem" {...props}>
      {heading && heading.trim() && (
        <Body
          className="paragraphSectionHeading"
          as="h2"
          size="md"
          fontWeight="bold"
          color="night"
          mb="1.25rem"
        >
          {heading}
        </Body>
      )}
      <Wrapper as="div" color="dusk">
        {children}
      </Wrapper>
    </Box>
  )
}
