import React from "react"
import { css } from "@emotion/core"

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Box,
} from "@chakra-ui/core"
import animations from "../../constants/animations"

export type ButtonThemeType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "toggled"
  | "rainbow"

export type ButtonProps = ChakraButtonProps & {
  theme?: ButtonThemeType
}

export const Button: React.FC<ButtonProps> = ({
  theme = "primary",
  ...props
}) => {
  let styles = {},
    hover = {},
    active = {}
  switch (theme) {
    case "primary":
      styles = {
        backgroundColor: "primary",
        color: "white",
        boxShadow: "0px 10px 30px rgba(0, 196, 209, 0.2)",
      }
      hover = {
        backgroundColor: "primary100",
      }
      active = {
        backgroundColor: "primary200",
      }
      break
    case "secondary":
      styles = {
        backgroundColor: "buttonSecondary",
        color: "dusk",
        fontWeight: "500",
      }
      hover = {
        backgroundColor: "buttonSecondary100",
      }
      active = {
        backgroundColor: "buttonSecondary200",
      }
      break
    case "tertiary":
      styles = {
        backgroundColor: "transparent",
        color: "dusk",
        border: "1px solid",
        borderColor: "noon",
      }
      hover = {
        backgroundColor: "buttonSecondary100",
      }
      active = {
        backgroundColor: "buttonSecondary200",
      }
      break
    case "toggled":
      styles = {
        backgroundColor: "night",
        color: "white",
        border: "1px solid",
        borderColor: "night",
      }
      hover = {
        backgroundColor: "nightLight7",
      }
      active = {
        backgroundColor: "nightLight20",
      }
      break
    case "rainbow":
      return <RainbowButton {...props} />
  }

  return (
    <ChakraButton
      className="questionOptionButtons"
      style={{
        width: "300px",
      }}
      variantColor="none"
      fontFamily="din"
      fontWeight="bold"
      fontSize="0.875rem"
      lineHeight="1.0625rem"
      letterSpacing="0.0625rem"
      textTransform="uppercase"
      whiteSpace="normal"
      borderRadius="0.5rem"
      h="3rem"
      px="2.5rem"
      {...styles}
      boxSizing="border-box"
      {...props}
      _hover={{
        ...hover,
        ...props._hover,
      }}
      _active={{
        ...active,
        ...props._active,
        transform: "scale(0.96)",
        outline: "none",
      }}
      _focus={{
        boxShadow: "",
        ...props._focus,
      }}
    />
  )
}

const rainbow =
  "linear-gradient(90deg, #FF8E4F 0%, #FFCE4F 25%, #A8E786 50%, #77D6D6 75%, #77B4D6 100%)"

export const RainbowButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <Button
    className="isbtn"
    background={rainbow}
    color="dusk"
    css={
      !props.isDisabled
        ? css`
            :hover {
              :before {
                opacity: 0.5;
              }
              :after {
                transform: scale(0.985, 0.95);
              }
            }
          `
        : undefined
    }
    boxShadow="none"
    _before={{
      content: `""`,
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 0,
      borderRadius: "0.5rem",
      background: rainbow,
      // @ts-ignore
      filter: "blur(0.625rem)",
      opacity: 0,
      transform: "translateY(0.3125rem)",
      transition: !props.isDisabled
        ? `opacity 700ms ${animations.bezier}`
        : undefined,
    }}
    _after={{
      content: `""`,
      display: "block",
      position: "absolute",
      left: "1px",
      top: "1px",
      bottom: "1px",
      right: "1px",
      zIndex: 0,
      borderRadius: "0.375rem",
      backgroundColor: "white",
      transition: !props.isDisabled
        ? `transform 300ms ${animations.bezier}`
        : undefined,
    }}
    _hover={{
      color: "night",
    }}
    fontWeight="medium"
    {...props}
  >
    <Box as="span" zIndex={1}>
      {children}
    </Box>
  </Button>
)
