import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { Link } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"
import { AssetValue, Value } from "contentful-layout-field"
import "../../styles/css/PrArticlesSection.css"

type PrArticlesProps = BoxProps & {
  imageMap?: any
  colorScheme?: any
  articles?: any
  logoGrid?: any
}

const StyledImage = styled(GatsbyImage)`
  width: auto;
  height: 100%;
`

export default function PrArticles({
  colorScheme,
  articles,
  logoGrid,
  imageMap,
  ...props
}: PrArticlesProps) {
  // Image Transform
  const transformImage = (image: AssetValue, mobile?: boolean) =>
    imageMap[(mobile ? image?.mobileAssetId : image?.assetId) || ""]

  return (
    <div className={colorScheme} id={colorScheme} {...props}>
      <div className="articles-section">
        {articles?.map(({ id, value }) => (
          <Link to={value?.url || ""} key={id} className="articleItem">
            <Box textAlign="center" className="articleItemDetails">
              <Body className="article-quote">{value?.title || ""}</Body>
              <span className="dash"> - </span>
              <StyledImage
                className="article-logo"
                fluid={transformImage(value?.image)}
              />
            </Box>
          </Link>
        ))}
      </div>
      <div className="logos-section">
        {logoGrid?.map(({ id, value }) => (
          <Link to={value?.url || ""} key={id} className="logoGridItem">
            <StyledImage
              className="logo-container"
              fluid={transformImage(value?.logo)}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}
