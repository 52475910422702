import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import Body from "../../typography/Body"

type SuccessWrapperProps = BoxProps & { success: boolean; message?: React.ReactNode }

export default function SuccessWrapper({
  success,
  message = "Your inquiry has been sent!",
  children,
  ...props
}: SuccessWrapperProps) {
  return (
    <Box {...props}>
      {!success && <>{children}</>}
      {success && (
        <Body my="3rem" fontWeight="bold" color="dusk" textAlign="center">
          {message}
        </Body>
      )}
    </Box>
  )
}
