import { Flex, PseudoBoxProps, Spinner, usePrevious } from "@chakra-ui/core"
import { AssetValue } from "contentful-layout-field"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import isString from "lodash/isString"
import React from "react"
import useJudgeMeReviews from "../../hooks/useJudgeMeReviews"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { decodeShopifyId, placeholderImage } from "../../utils"
import { bp } from "../../utils/MediaQueries"
import { FitType } from "../BikeProductPage/QuickFit"
import { getFixedPrice } from "../Cart/utils"
import { ColorType } from "../ColorPicker"
import SlashedText from "../SlashedText"
import Body from "../typography/Body"

export interface BikeVariant {
  uniqueId: string
  productHandle: string
  speed: string
  color: { internalTitle: string; hexCode: string; name: string }
  isNew: boolean
  forSale: boolean
  image?: FluidObject | string
  pricing?: ShopifyVariantPricing
}

export type BaseBikeProductCardProps = {
  title: string
  variants?: Array<BikeVariant>
  to?: string
  matchPercent?: number // 98 = 98%
  bestMatch?: boolean // Makes match badge a different color
  fitStatus?: FitType | null
  // @ts-ignore
  onClick?: ({ speed: string, color: ColorType, variant: BikeVariant }) => void
  onSpeedChange?: (speed: string) => void
  onColorChange?: (color: ColorType) => void
  showCompare?: boolean
  onCompare?: (variant: BikeVariant) => void
  view?: "normal" | "toggle"
  selected?: boolean
  imageMap?: any
  logo?: any
  score?: any
  index?: any
  displayCustomInfo?: any
  customBikeImg?: any
  customBikeTitle?: any
  customBikePrice?: any
  customReviewsCount?: any
  customBikeURL?: any
}

export type BikeProductCardProps = Omit<PseudoBoxProps, "onClick"> &
  BaseBikeProductCardProps

const RelatedBikeCard: React.FC<BikeProductCardProps> = ({
  title,
  matchPercent,
  onClick,
  to,
  bestMatch = false,
  fitStatus,
  onSpeedChange = (speed: string) => {},
  onColorChange = (color: ColorType) => {},
  variants,
  showCompare,
  onCompare,
  view = "normal",
  selected = false,
  imageMap,
  logo,
  score,
  index,
  displayCustomInfo,
  customBikeImg,
  customBikeTitle,
  customBikePrice,
  customReviewsCount,
  customBikeURL,
  ...props
}) => {
  const transformImage = (image: AssetValue, mobile?: boolean) =>
    imageMap[(mobile ? image?.mobileAssetId : image?.assetId) || ""]

  const filteredVariants = variants?.filter(
    ({ color, speed }) => color !== null && speed !== null
  )
  if (!filteredVariants || filteredVariants.length === 0) return null

  const [currentShopifyId, setCurrentShopifyId] = React.useState<string>(
    filteredVariants[0].uniqueId
  )
  const [currentSpeed, setCurrentSpeed] = React.useState<string>(
    filteredVariants[0].speed
  )
  const [currentColor, setCurrentColor] = React.useState<string>(
    filteredVariants[0].color?.name
  )
  const currentVariant = filteredVariants.find(
    (variant) => variant.uniqueId === currentShopifyId
  )
  const pricing = currentVariant?.pricing
  const currentPrice = getFixedPrice(pricing?.priceV2.amount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing?.compareAtPriceV2.amount)
    : null
  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock
  const noticeText =
    !pricing || isAvailable
      ? null
      : isPreorder
      ? "(Preorder)"
      : "(Out of Stock)"
  const previousSpeed = usePrevious(currentSpeed)
  const previousColor = usePrevious(currentColor)
  const productHandle = currentVariant?.productHandle
  const previousProductHandle = usePrevious(productHandle)
  const reviews = useJudgeMeReviews(productHandle, {
    lazy: false,
    minimal: true,
  })

  const onCurrentColorChange = (color: ColorType) => {
    setCurrentColor(color.colorId)
    onColorChange(color)
  }

  const onCurrentSpeedChange = (speed: string) => {
    setCurrentSpeed(speed)
    onSpeedChange(speed)
  }

  // Fetch review product/speed was changed
  React.useEffect(() => {
    if (
      !reviews.fetched &&
      !reviews.isLoading &&
      previousProductHandle &&
      productHandle &&
      previousProductHandle !== productHandle
    ) {
      reviews.getJudgemeData()
    }
  }, [reviews.fetched, reviews.isLoading, productHandle, previousProductHandle])

  // Sync default speed/color variants
  React.useEffect(() => {
    const newVariant = filteredVariants.find(
      (variant) =>
        variant.speed === currentSpeed && variant.color?.name === currentColor
    )

    if (newVariant) {
      setCurrentShopifyId(newVariant.uniqueId)
    } else {
      // There isn't a variant with the new speed-color combo
      // Find a default variant to set:
      if (currentSpeed !== previousSpeed) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.speed === currentSpeed
        )
        if (defaultVariant) {
          onCurrentColorChange({
            colorId: defaultVariant.color.name,
            colorCode: defaultVariant.color.hexCode,
          })
        }
      } else if (currentColor !== previousColor) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.color.name === currentColor
        )
        if (defaultVariant) {
          onCurrentSpeedChange(defaultVariant.speed)
        }
      } else {
        setCurrentShopifyId(filteredVariants[0].uniqueId)
      }
    }
  }, [filteredVariants, currentColor, currentSpeed])

  let logoData = transformImage(logo)

  if (!score || score == "" || score == null) {
    score = 0
  }

  var newCustomBikeImg
  var newCustomBikeTitle
  var newCustomBikePrice
  var newCustomReviewsCount
  var newCustomBikeURL = `${to}/?variant=${decodeShopifyId(currentShopifyId)}`

  var tempCustomBikeImage = transformImage(customBikeImg)

  // Bike Image
  if (tempCustomBikeImage && displayCustomInfo) {
    newCustomBikeImg = tempCustomBikeImage?.src
  } else {
    if (isString(currentVariant?.image)) {
      newCustomBikeImg = currentVariant?.image ?? placeholderImage.src
    } else {
      newCustomBikeImg = currentVariant?.image?.src ?? placeholderImage.src
    }
  }

  if (displayCustomInfo && displayCustomInfo == true) {
    newCustomBikeTitle = customBikeTitle ? customBikeTitle : title
    newCustomBikePrice = customBikePrice ? "$" + customBikePrice : currentPrice
    newCustomBikeURL = customBikeURL
      ? customBikeURL
      : `${to}/?variant=${decodeShopifyId(currentShopifyId)}`
  }

  let bikeBGImage = `url(${newCustomBikeImg})`

  return (
    <Flex
      as={to && view !== "toggle" ? Link : "div"}
      // @ts-ignore
      to={newCustomBikeURL}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      style={{ gap: "10px" }}
      borderBottom="1px solid #ccc"
      padding={bp("13px 0", "17px 0")}
      className="relatedBikeCard"
    >
      <Flex
        className="relatedBikeLeft"
        justifyContent={"flex-start"}
        alignContent={"center"}
        alignItems={"center"}
        w={bp("70%", "49.3%")}
      >
        <Flex className="counterContainer" w="50px">
          <Body fontWeight={500} fontSize={bp("13px", "16px")}>
            #{++index}
          </Body>
        </Flex>
        <Flex
          w="150px"
          h="90px"
          className="bikeImageContainer"
          justifyContent={"flex-start"}
          alignContent={"center"}
          alignItems={"center"}
          backgroundImage={bikeBGImage}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
        >
          {/* <img
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            src={newCustomBikeImg}
          /> */}
        </Flex>
        <Flex
          className="bikeTitleContainer"
          h="100%"
          fontWeight={500}
          w="67%"
          paddingLeft="15px"
        >
          <Body fontSize={bp("13px", "16px")}>
            {newCustomBikeTitle ?? title}
          </Body>
        </Flex>
      </Flex>

      <Flex
        className="relatedBikeRight"
        justifyContent={"flex-start"}
        alignContent={"center"}
        alignItems={"center"}
        w={bp("30%", "49.3%")}
      >
        <Flex
          className="priceContainer"
          flexDirection={"column-reverse"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          w={bp("60%", "25%")}
        >
          <Body fontSize={bp("13px", "16px")} fontWeight={500}>
            {newCustomBikePrice ?? currentPrice}
          </Body>
          {compareAtPrice && !customBikeTitle && (
            <SlashedText
              as="span"
              fontSize={bp("10px", "12px")}
              fontWeight={500}
              // color="#777"
              className="slashedPrice"
            >
              {compareAtPrice}
            </SlashedText>
          )}
        </Flex>
        <Flex
          className="companyLogoContainer"
          justifyContent={"center"}
          h="100%"
          w={bp("49%", "25%")}
          display={bp("none", "flex")}
        >
          <img
            style={{ objectFit: "cover", maxHeight: "60px", width: "60px" }}
            src={logoData?.src ? logoData?.src : placeholderImage.src}
          />
        </Flex>
        <Flex
          className="scoreContainer"
          h="100%"
          justifyContent={"center"}
          w={bp("40%", "25%")}
        >
          <Body
            title="The bike score ranks bikes from 0-100 based on opinions from users and experts. "
            fontSize={bp("13px", "16px")}
            fontWeight={600}
            background={score ? "#005f78" : "#005f78a8"}
            padding="0 5px"
            color="#fff"
            borderRadius="4px"
            width={bp("fit-content", "50px")}
            textAlign="center"
          >
            {score ? score : "NA"}
          </Body>
        </Flex>
        <Flex
          align="center"
          flexWrap="wrap"
          gridRowGap="0.5rem"
          h="100%"
          w={bp("49%", "25%")}
          justifyContent={"flex-end"}
          fontWeight={500}
          display={bp("none", "flex")}
        >
          {reviews ? (
            reviews.isLoading ? (
              <Spinner size="sm" color="primary" label="Loading reviews" />
            ) : (
              <Body
                size="md"
                color="primary"
                fontWeight={500}
                fontSize={bp("13px", "16px")}
              >
                {displayCustomInfo
                  ? customReviewsCount ?? reviews.numReviews
                  : reviews.numReviews}{" "}
                Reviews
              </Body>
            )
          ) : (
            <Body
              size="md"
              color="primary"
              fontWeight={500}
              fontSize={bp("13px", "16px")}
            >
              {customReviewsCount} Reviews
            </Body>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RelatedBikeCard
