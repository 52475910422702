import React from "react"
import { Box, Grid } from "@chakra-ui/core"

import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Body from "../typography/Body"
import { bpWithSidebar } from "../../utils/MediaQueries"
import { useIsSideBarOpen } from "../../hooks/useIsSidebarOpen"

export type SpecListProps = MaxWidthGridProps & {
  specs: Array<{ label: string; value: string }>
}

const SpecList: React.FC<SpecListProps> = ({ specs, ...props }) => {
  const isSideBarOpen = useIsSideBarOpen()

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props}>
      <Box
        height="1px"
        gridColumn={bpWithSidebar("1 / 5", "3 / 13", isSideBarOpen)}
        bg="dividerLine"
        mb={bpWithSidebar("2rem", "4.375rem", isSideBarOpen)}
      />
      <Grid
        gridColumn={bpWithSidebar("1 / 5", "3 / 13", isSideBarOpen)}
        gridTemplateColumns={bpWithSidebar(
          "1fr",
          "max-content 2fr max-content 2fr",
          isSideBarOpen
        )}
        columnGap={bpWithSidebar("0px", "1.25rem", isSideBarOpen)}
      >
        {specs
          .filter((spec) => spec.label && spec.value)
          .map((spec, index) => (
            <React.Fragment key={index}>
              <Body
                color="dusk"
                size="md"
                my={bpWithSidebar("0.25rem", "0.5rem", isSideBarOpen)}
                mr="1.875rem"
                textTransform="capitalize"
              >
                {spec.label.toLowerCase()}
              </Body>
              <Body
                color="dawn"
                size="md"
                m={bpWithSidebar("0.25rem 0 1rem", "0.5rem 0", isSideBarOpen)}
              >
                {spec.value}
              </Body>
            </React.Fragment>
          ))}
      </Grid>
      <Box
        height="1px"
        gridColumn={bpWithSidebar("1 / 5", "3 / 13", isSideBarOpen)}
        bg="dividerLine"
        mt={bpWithSidebar("2rem", "4.375rem", isSideBarOpen)}
      />
    </MaxWidthGrid>
  )
}

export default SpecList
