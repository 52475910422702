import React from "react"
import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  PseudoBox,
  Skeleton,
} from "@chakra-ui/core"
import Axios from "axios"
import ModalCloseButton from "../Buttons/ModalCloseButton"

type VimeoBlockProps = BoxProps & {
  vimeoId: string
}

type VimeoMetadata = {
  url: string
  thumbnail_large: string
}

export default function VimeoBlock({ vimeoId, ...props }: VimeoBlockProps) {
  const [metadata, setMetadata] = React.useState<VimeoMetadata | null>(null)
  const [showVideo, setShowVideo] = React.useState(false)

  React.useEffect(() => {
    const getMetaData = async () => {
      const response = await Axios.get(
        `https://vimeo.com/api/v2/video/${vimeoId}.json`
      )
      if (response?.data) {
        const data = response.data?.[0]
        if (data) {
          setMetadata(data)
        }
      }
    }
    getMetaData()
  }, [vimeoId])

  return (
    <Box
      pos="relative"
      w="24.125rem"
      h="24.125rem"
      borderRadius="0.5rem"
      overflow="hidden"
      {...props}
    >
      <Skeleton
        isLoaded={!!metadata}
        w="100%"
        h="100%"
        colorStart="#EBF0F1"
        colorEnd="#909AA0"
      >
        {!showVideo && (
          <>
            <Flex
              pos="absolute"
              top={0}
              left={0}
              zIndex={2}
              w="100%"
              h="100%"
              align="center"
              justify="center"
              pointerEvents="none"
            >
              <Icon name="play" color="white" size="2.5rem" />
            </Flex>
            <PseudoBox
              pos="absolute"
              top={0}
              left={0}
              zIndex={1}
              w="100%"
              h="100%"
              transition="opacity 300ms ease"
              backgroundColor="rgb(44, 46, 52)"
              opacity={0.28}
              _hover={{
                opacity: 0.1,
              }}
              cursor="pointer"
              onClick={() => setShowVideo(true)}
            />
            <Box
              w="100%"
              h="100%"
              backgroundImage={`url(${metadata?.thumbnail_large})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundColor="night"
              backgroundRepeat="no-repeat"
            />
          </>
        )}
        {showVideo && (
          <Box
            as="iframe"
            width="100%"
            height="100%"
            // @ts-ignore
            src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&title=0&byline=0&portrait=0&responsive=1`}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            backgroundColor="black"
          ></Box>
        )}
      </Skeleton>
    </Box>
  )
}
