import React from "react"
import { useForm } from "react-hook-form"

type GoogleSheetsFormOptions = {
  sheetsId: string
  onSuccess?: VoidFunction
}

export default function useGoogleForm<FormValues>({
  sheetsId,
  onSuccess,
}: GoogleSheetsFormOptions) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const methods = useForm<FormValues>()
  const onSubmit = async ({ name, email, ...data }) => {
    setIsSubmitting(true)
    setSuccess(false)
    setError(null)
    try {
      const response = await fetch(`/.netlify/functions/googleForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, id: sheetsId }),
      })
      if (response.ok) {
        onSuccess?.()
        setSuccess(true)
        methods.reset()
      }
    } catch (e) {
      setError(e.message)
    }
    setIsSubmitting(false)
  }

  return { methods, success, error, onSubmit, isSubmitting }
}
