import { useSelector } from "react-redux"
import { BaseRootState } from "../redux/store"

export function useIsSideBarOpen() {
  const { isSideBarOpen } = useSelector(({ sidebar }: BaseRootState) => ({
    isSideBarOpen: sidebar.isOpen,
  }))

  return isSideBarOpen
}
