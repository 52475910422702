import { Grid, GridProps } from "@chakra-ui/core"
import { AssetValue, BikeBulkContentLayout } from "contentful-layout-field"
import { FluidObject } from "gatsby-image"
import R from "ramda"
import React from "react"
import { useSelector } from "react-redux"

import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { BaseRootState } from "../../redux/store"
import { BikeType } from "../../redux/types/BikeTypes"
import { placeholderImage } from "../../utils"
import { mapURL } from "../../utils/url"
import BikeBulkContentBlock, { BikeVariant } from "./BikeBulkContentBlock"
import "../../styles/css/BikeBulkContent.css"

type BikeBulkContentProps = GridProps & {
  bikeEntries: BikeBulkContentLayout["bikes"]
} & {
  sectiontitle?: string
  features?: string
  body?: string
  image?: AssetValue & {
    asset: FluidObject
  }
}

export default function BikeBulkContent({
  bikeEntries,
  sectiontitle,
  features,
  image,
  body,
  ...props
}: BikeBulkContentProps) {
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)
  const { bikes: allBikes } = useSelector((state: BaseRootState) => state.bikes)

  const bikeModels = (
    bikeEntries
      .map((bikeEntry) =>
        allBikes.find(
          // @ts-ignore
          (bikeModel) => bikeModel?.id === bikeEntry?.value?.entry?.entryId
        )
      )
      .filter((bike) => !!bike) as BikeType[]
  ).map((bikeModel) => ({
    ...bikeModel,
    variants: R.flatten(
      //@ts-ignore
      bikeModel?.speeds?.map(
        (speed) =>
          speed?.variants?.map((variant) => ({
            uniqueId: variant?.shopifyId || "",
            productHandle: speed.internalTitle || "",
            speed: speed.speed || "",
            color: {
              internalTitle: variant?.color?.internalTitle || "",
              hexCode: variant?.color?.hexCode || "#FFF",
              name: variant?.color?.name || "-",
            },
            image: (variant?.productListingImage?.fluid ||
              placeholderImage) as FluidObject,
          })) || []
      ) || []
    ),
  }))

  const pricing = useShopifyVariantPricing(
    R.flatten(
      bikeModels.map((bikeModel) =>
        bikeModel.variants.map((variant) => variant.uniqueId)
      )
    )
  )

  const bikes = bikeModels.map(({ title, internalTitle, variants }) => ({
    id: internalTitle,
    title: title || "",
    to: mapURL("bike-page", internalTitle),
    variants: variants.map(
      (variant) =>
        ({
          ...variant,
          pricing: pricing.pricingMap[variant.uniqueId],
        } as BikeVariant)
    ),
    showCompare: false,
  }))

  return (
    <Grid
      gridTemplateColumns="repeat( auto-fit, minmax(400px, 1fr) )"
      gridColumn={["1/end", null, null, null, "2/14", "1/end"]}
      marginX={[null, null, null, null, null, "1.25rem"]}
      alignItems="center"
      justifyContent="center"
      gridGap="1rem"
      {...props}
    >
      {bikes
        .filter((item, index) => index < 1)
        .map(({ id, ...bike }) => (
          // @ts-ignore
          <BikeBulkContentBlock
            sectiontitle={sectiontitle}
            features={features}
            body={body}
            customImage={image?.asset?.src}
            key={id}
            {...bike}
          />
        ))}
    </Grid>
  )
}
