import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import Heading from "../typography/Heading"
import SmallCaps from "../typography/SmallCaps"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"

export type FigureProps = FlexProps & {
  figure: string
  figureTitle: string
  description: string
}

export default function Figure({
  figure,
  figureTitle,
  description,
  ...props
}: FigureProps) {
  return (
    <Flex direction="column" align="center" textAlign="center" {...props}>
      <Heading
        size="2"
        as="span"
        d="block"
        color="night"
        fontWeight="bold"
        mb={bp("1.6875rem", "2.625rem")}
      >
        {figure}
      </Heading>
      <SmallCaps size="large" fontWeight="bold" color="night" mb="0.75rem">
        {figureTitle}
      </SmallCaps>
      <Body size="md" color="dusk">
        {description}
      </Body>
    </Flex>
  )
}
