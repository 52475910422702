import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import FormTitle from "../common/FormTitle"
import FormBox from "../common/FormBox"
import FormDescription from "../common/FormDescription"
import FormFieldsLayout from "../common/FormFieldsLayout"
import TextField from "../../FormFields/TextField"
import DateField from "../../FormFields/DateField"
import FormSubtitle from "../common/FormSubtitle"
import Body from "../../typography/Body"
import TextAreaField from "../../FormFields/TextAreaField"
import { Button } from "../../Buttons"
import StoreSelectField from "../common/StoreSelectField"
import useGoogleForm from "../../../hooks/useGoogleForm"
import config from "../../../config"
import { FormProvider } from "react-hook-form"
import SuccessWrapper from "./SuccessWrapper"

type WrongBikeDeliveredFormProps = BoxProps & {}

export default function WrongBikeDeliveredForm({
  ...props
}: WrongBikeDeliveredFormProps) {
  const { methods, onSubmit, isSubmitting, success, error } = useGoogleForm<{}>(
    {
      sheetsId: config.GATSBY_SHIPPING_ADDRESS_GOOGLE_SHEETS_ID,
    }
  )

  return (
    <FormProvider {...methods}>
      <FormBox {...props} onSubmit={methods.handleSubmit(onSubmit)}>
        <SuccessWrapper success={success}>
          <FormTitle>Wrong Product Received</FormTitle>
          <FormDescription>
            We&apos;re sorry to hear you didn&apos;t` receive exactly what you
            ordered. We&apos;d love the chance to make this right.
            <br />
            <br />
            We have a larger amount of inquires than normal and we&apos;re
            working through the requests as quickly as possible. We appreciate
            your patience.
            <br />
            <br />
            Please complete the form below and our team will be notified
            you&apos;re in the queue to be helped.
          </FormDescription>
          <FormFieldsLayout mb="2.9531rem">
            <TextField name="name" placeholder="Name on Order" isRequired />
            <TextField
              name="Email Address"
              placeholder="Email address"
              type="email"
              isRequired
            />
            <DateField name="Order Date" placeholder="Order Date" isRequired />
            <StoreSelectField name="Where did you purchase your bike?" />
            <TextField
              name="Order Number"
              placeholder="Order Number"
              isRequired
            />
          </FormFieldsLayout>
          <FormSubtitle mb="0.5rem">
            What bike or item should you have received?
          </FormSubtitle>
          <Body size="sm" color="dusk" mb="0.5rem">
            Please enter bike name(s) or SKU(s) below:
          </Body>
          <TextField
            name="What bike or item should you have received?"
            placeholder="Bike name(s) or SKU(s)"
            mb="2rem"
          />
          <FormSubtitle mb="0.5rem">
            What bike or item did you receive instead?
          </FormSubtitle>
          <Body size="sm" color="dusk" mb="0.5rem">
            Please enter bike name(s) or SKU(s) below:
          </Body>
          <TextField
            name="What bike or item did you receive instead?"
            placeholder="Bike name(s) or SKU(s)"
            mb="2rem"
          />
          <TextAreaField
            name="Additional Comments:"
            placeholder="Additional comments"
          />
          <Box textAlign="center" mt="4.32rem">
            <Button theme="primary" type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </Box>
          {error && (
            <Body mt="2rem" color="sunrise">
              {error}
            </Body>
          )}
        </SuccessWrapper>
      </FormBox>
    </FormProvider>
  )
}
