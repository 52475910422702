import React from "react"
import { Box, Flex, FlexProps } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import DragSlider from "../DragSlider"
import { bpWithSidebar } from "../../utils/MediaQueries"
import BackgroundImage from "../BackgroundImage"
import { useIsSideBarOpen } from "../../hooks/useIsSidebarOpen"

export interface FullWidthImageCarouselWithBleedProps {
  images: Array<FluidObject>
}

const FullWidthImageCarouselWithBleed: React.FC<
  FullWidthImageCarouselWithBleedProps & FlexProps
> = ({ images, ...props }) => {
  const isSideBarOpen = useIsSideBarOpen()
  return (
    <Flex
      w="100%"
      h={bpWithSidebar("13.75rem", "33.75rem", isSideBarOpen)}
      {...props}
    >
      <DragSlider isSideBarOpen={isSideBarOpen}>
        {images.map(
          (image, index) =>
            image && (
              <Box
                key={index}
                h={bpWithSidebar("13.75rem", "33.75rem", isSideBarOpen)}
                width={bpWithSidebar(
                  `${13.75 * image.aspectRatio}rem`,
                  `${33.75 * image.aspectRatio}rem`,
                  isSideBarOpen
                )}
              >
                <BackgroundImage
                  h="100%"
                  w="100%"
                  fluid={image}
                  draggable={false}
                />
              </Box>
            )
        )}
      </DragSlider>
    </Flex>
  )
}

export default FullWidthImageCarouselWithBleed
