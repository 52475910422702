import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import Image, { FluidObject } from "gatsby-image"

import Heading from "../typography/Heading"
import Body from "../typography/Body"
import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"

const ImageCard: React.FC<
  {
    fluid: FluidObject
    title: string
    body: string
  } & BoxProps
> = ({ fluid, title, body, ...props }) => (
  <Box m={["2.5rem 0", null, null, null, "0"]} {...props} textAlign="center">
    <Image fluid={fluid} loading="eager" fadeIn={false} />
    <Heading
      size="6"
      fontWeight="bold"
      mt="2.5rem"
      color="black"
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <Body
      mt="1.125rem"
      color="dawn"
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </Box>
)

export type SplitImageCardsProps = MaxWidthGridProps & {
  imageLeft: FluidObject
  imageRight: FluidObject
  leftTitle: string
  rightTitle: string
  leftBody: string
  rightBody: string
}

const SplitImageCards: React.FC<SplitImageCardsProps> = ({
  imageLeft,
  imageRight,
  leftTitle,
  leftBody,
  rightTitle,
  rightBody,
  ...props
}) => (
  <MaxWidthGrid {...props}>
    <ImageCard
      fluid={imageLeft}
      title={leftTitle}
      body={leftBody}
      gridColumn={["1 / 3", null, null, null, "3 / 8"]}
      mr={[null, null, null, null, "2.5rem"]}
    />
    <ImageCard
      fluid={imageRight}
      title={rightTitle}
      body={rightBody}
      gridColumn={["1 / 3", null, null, null, "8 / 13"]}
      ml={[null, null, null, null, "2.5rem"]}
    />
  </MaxWidthGrid>
)

export default SplitImageCards
