import React from "react"
import Body, { BodyProps } from "../../typography/Body"

type FormTitleProps = BodyProps & {}

export default function FormTitle({ ...props }: FormTitleProps) {
  return (
    <Body size="md" fontWeight="600" mb="0.8844rem" color="night" {...props} />
  )
}
