import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"

type FullBleedImageSectionProps = BoxProps & {
  image: FluidObject
  mobileImage?: FluidObject
  eager?: boolean
}

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 90vh;
`

// const StyledImage = styled.img`
//   width: 100%;
//   max-height: 90vh;
//   object-fit: cover;
// `

// const Image: React.FC<{
//   image: FluidObject
//   eager?: boolean
// }> = ({ image, eager }) =>
//   eager ? (
//     <StyledImage
//       src={image?.srcSetWebp || image?.src}
//       srcSet={image?.srcSetWebp || image?.srcSet}
//     />
//   ) : (
//     <StyledGatsbyImage fluid={image} loading={eager ? "eager" : "auto"} />
//   )

const FullBleedImageSection = ({
  image,
  mobileImage,
  eager,
  ...props
}: FullBleedImageSectionProps) => {
  return (
    <Box textAlign="center" {...props}>
      {mobileImage ? (
        <>
          <LaptopDownOnly>
            <StyledGatsbyImage
              fluid={mobileImage}
              loading={eager ? "eager" : "lazy"}
              fadeIn={!eager}
              imgStyle={{ objectFit: "contain" }}
            />
          </LaptopDownOnly>
          <LaptopUpOnly>
            <StyledGatsbyImage
              fluid={image}
              loading={eager ? "eager" : "lazy"}
              fadeIn={!eager}
              imgStyle={{ objectFit: "contain" }}
            />
          </LaptopUpOnly>
        </>
      ) : (
        <StyledGatsbyImage
          fluid={image}
          loading={eager ? "eager" : "lazy"}
          fadeIn={!eager}
          imgStyle={{ objectFit: "contain" }}
        />
      )}
    </Box>
  )
}

export default FullBleedImageSection
