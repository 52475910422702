import React from "react"
import * as R from "ramda"
import {
  Box,
  BoxProps,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Select,
} from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import AssemblyBlock from "./AssemblyBlock"
import Embed from "../Embed"
import { bp } from "../../utils/MediaQueries"

type AssemblyInstructionsProps = BoxProps & {
  items: {
    id: string
    image: FluidObject
    label: string
    pdfUrl?: string
    videoEmbed?: string
    model?: string
    configuration?: string
  }[]
}

export default function AssemblyInstructions({
  items,
  ...props
}: AssemblyInstructionsProps) {
  const [modelFilter, setModalFilter] = React.useState<string | null>(null)
  const [configFilter, setConfigFilter] = React.useState<string | null>(null)
  const [video, setVideo] = React.useState<string | null>(null)

  const mappedItems = items.map((item) => ({
    ...item,
    model: R.flatten(
      item?.model
        ?.split(",")
        .map((model) => (model ? model.trim() : null))
        .filter((item) => item !== null) || []
    ) as string[],
    configuration: R.flatten(
      item?.configuration
        ?.split(",")
        .map((config) => (config ? config.trim() : null))
        .filter((item) => item !== null) || []
    ) as string[],
  }))

  const models = R.uniq(R.flatten(mappedItems.map((item) => item.model))).sort(
    (a, b) => {
      if (a === "Parts Assembly" || a === "Tuning/Repairs") {
        return -1
      }
      return a?.localeCompare(b || "") || 0
    }
  )
  const configs = R.uniq(
    R.flatten(mappedItems.map((item) => item.configuration))
  ).sort((a, b) => {
    if (a === "Single Speed") {
      a = "1 Speed"
    }
    if (b === "Single Speed") {
      b = "1 Speed"
    }
    return a?.localeCompare(b || "", "en", { numeric: true }) || 0
  })

  const handleVideoClick = (videoEmbed) => {
    setVideo(videoEmbed)
  }

  return (
    <Box {...props}>
      <Flex
        flexDir={bp("column", "row")}
        align="center"
        justify="center"
        mb="8.2556rem"
      >
        <Select
          placeholder="Model"
          onChange={(e) =>
            setModalFilter(
              e.currentTarget.value ? e.currentTarget.value || "" : null
            )
          }
          h="2.75rem"
          border="1px solid"
          borderColor="dividerLine"
          mr={bp("0", "0.75rem")}
          mb={bp("0.75rem", "0")}
        >
          {models.map((model) => (
            <option key={model}>{model}</option>
          ))}
        </Select>
        <Select
          placeholder="Speed/Configuration"
          onChange={(e) =>
            setConfigFilter(
              e.currentTarget.value ? e.currentTarget.value : null
            )
          }
          h="2.75rem"
          border="1px solid"
          borderColor="dividerLine"
        >
          {configs.map((model) => (
            <option key={model}>{model}</option>
          ))}
        </Select>
      </Flex>
      <SimpleGrid
        columns={[1, null, null, 2, 4]}
        spacingX="0.9169rem"
        spacingY="2.5rem"
      >
        {mappedItems
          .filter(
            (item) =>
              (modelFilter === null && configFilter === null) ||
              ((!modelFilter || item.model?.includes(modelFilter)) &&
                (!configFilter || item.configuration?.includes(configFilter)))
          )
          .map((item) => (
            <AssemblyBlock
              key={item.id}
              image={item.image}
              label={item.label}
              pdfUrl={item.pdfUrl}
              videoEmbed={item.videoEmbed}
              onVideoClick={handleVideoClick}
            />
          ))}
      </SimpleGrid>
      <Modal
        isOpen={!!video}
        onClose={() => setVideo(null)}
        closeOnOverlayClick
        isCentered
        size="50vh"
      >
        <ModalOverlay />
        <ModalContent>
          {!video?.match("iframe") ? (
            <Box position="relative" pb="56.25%">
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                src={video || ""}
              />
            </Box>
          ) : (
            <Embed responsive code={video!} />
          )}
        </ModalContent>
      </Modal>
    </Box>
  )
}
