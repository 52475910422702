import { Box, PseudoBoxProps, usePrevious, Flex } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import React from "react"
import { Link } from "gatsby"
import useJudgeMeReviews from "../../hooks/useJudgeMeReviews"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { decodeShopifyId } from "../../utils"
import { FitType } from "../BikeProductPage/QuickFit"
import { getFixedPrice } from "../Cart/utils"
import { ColorType } from "../ColorPicker"
import Paragraph from "../typography/Paragraph"
import { bp } from "../../utils/MediaQueries"

export interface BikeVariant {
  uniqueId: string
  productHandle: string
  speed: string
  color: { internalTitle: string; hexCode: string; name: string }
  isNew: boolean
  forSale: boolean
  image?: FluidObject | string
  pricing?: ShopifyVariantPricing
}

export type BaseBikeProductCardProps = {
  sectiontitle?: string
  features?: string
  body?: string
  customImage?: string
  title: string
  variants?: Array<BikeVariant>
  to?: string
  matchPercent?: number // 98 = 98%
  bestMatch?: boolean // Makes match badge a different color
  fitStatus?: FitType | null
  onClick?: ({ speed: string, color: ColorType, variant: BikeVariant }) => void
  onSpeedChange?: (speed: string) => void
  onColorChange?: (color: ColorType) => void
  showCompare?: boolean
  onCompare?: (variant: BikeVariant) => void
  view?: "normal" | "toggle"
  selected?: boolean
}

export type BikeProductCardProps = Omit<PseudoBoxProps, "onClick"> &
  BaseBikeProductCardProps

const BikeDetailsProductCard: React.FC<BikeProductCardProps> = ({
  sectiontitle,
  features,
  body,
  customImage,
  title,
  matchPercent,
  onClick,
  to,
  bestMatch = false,
  fitStatus,
  onSpeedChange = (speed: string) => {},
  onColorChange = (color: ColorType) => {},
  variants,
  showCompare,
  onCompare,
  view = "normal",
  selected = false,
  ...props
}) => {
  const filteredVariants = variants?.filter(
    ({ color, speed }) => color !== null && speed !== null
  )
  if (!filteredVariants || filteredVariants.length === 0) return null

  const [currentShopifyId, setCurrentShopifyId] = React.useState<string>(
    filteredVariants[0].uniqueId
  )
  const [currentSpeed, setCurrentSpeed] = React.useState<string>(
    filteredVariants[0].speed
  )
  const [currentColor, setCurrentColor] = React.useState<string>(
    filteredVariants[0].color?.name
  )
  const currentVariant = filteredVariants.find(
    (variant) => variant.uniqueId === currentShopifyId
  )
  const pricing = currentVariant?.pricing
  const currentPrice = getFixedPrice(pricing?.priceV2.amount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing?.compareAtPriceV2.amount)
    : null
  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock
  const noticeText =
    !pricing || isAvailable
      ? null
      : isPreorder
      ? "(Preorder)"
      : "(Out of Stock)"
  const previousSpeed = usePrevious(currentSpeed)
  const previousColor = usePrevious(currentColor)
  const productHandle = currentVariant?.productHandle
  const previousProductHandle = usePrevious(productHandle)
  const reviews = useJudgeMeReviews(productHandle, {
    lazy: true,
    minimal: true,
  })

  const onCurrentColorChange = (color: ColorType) => {
    setCurrentColor(color.colorId)
    onColorChange(color)
  }

  const onCurrentSpeedChange = (speed: string) => {
    setCurrentSpeed(speed)
    onSpeedChange(speed)
  }

  const handleClick = () => {
    onClick?.({
      speed: currentSpeed,
      color: currentColor,
      variant: currentVariant,
    })
  }

  // Fetch review product/speed was changed
  React.useEffect(() => {
    if (
      !reviews.fetched &&
      !reviews.isLoading &&
      previousProductHandle &&
      productHandle &&
      previousProductHandle !== productHandle
    ) {
      reviews.getJudgemeData()
    }
  }, [reviews.fetched, reviews.isLoading, productHandle, previousProductHandle])

  // Sync default speed/color variants
  React.useEffect(() => {
    const newVariant = filteredVariants.find(
      (variant) =>
        variant.speed === currentSpeed && variant.color?.name === currentColor
    )

    if (newVariant) {
      setCurrentShopifyId(newVariant.uniqueId)
    } else {
      // There isn't a variant with the new speed-color combo
      // Find a default variant to set:
      if (currentSpeed !== previousSpeed) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.speed === currentSpeed
        )
        if (defaultVariant) {
          onCurrentColorChange({
            colorId: defaultVariant.color.name,
            colorCode: defaultVariant.color.hexCode,
          })
        }
      } else if (currentColor !== previousColor) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.color.name === currentColor
        )
        if (defaultVariant) {
          onCurrentSpeedChange(defaultVariant.speed)
        }
      } else {
        setCurrentShopifyId(filteredVariants[0].uniqueId)
      }
    }
  }, [filteredVariants, currentColor, currentSpeed])

  return (
    <div className="BikeContentWrapper">
      <p className="BikeBulkSectionTitle">{sectiontitle}</p>
      <div className="BikeBulkContentContainer">
        <div className="BikeBulkContentImageContainer">
          <Box
            as={to && view !== "toggle" ? Link : "div"}
            // @ts-ignore
            to={`${to}/?variant=${decodeShopifyId(currentShopifyId)}`}
            onClick={handleClick}
            className="BikeBulkContentImageLink"
          >
            <img
              src={customImage}
              alt={title}
              title={title}
              className="BikeBulkContentImage"
            />
          </Box>
        </div>
        <div className="BikeBulkContentInfoContainer">
          <div className="BikeBulkContentTitleContainer">
            <Box
              as={to && view !== "toggle" ? Link : "div"}
              // @ts-ignore
              to={`${to}/?variant=${decodeShopifyId(currentShopifyId)}`}
              onClick={handleClick}
              className="BikeBulkContentNameLink"
            >
              <h1 className="BikeBulkBikeName">{title}</h1>
            </Box>
            <Flex
              className="BikeBulkPriceContainer"
              flexDirection={bp("column !important", "row !important")}
              alignItems={bp("flex-end", "center")}
            >
              <span className="BikeBulkContentCurrentPrice">
                From {currentPrice}
              </span>
              {compareAtPrice && (
                <del
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    color: "#e22929",
                  }}
                  className="BikeBulkContentSlashedPrice"
                >
                  {compareAtPrice}
                </del>
              )}
            </Flex>
          </div>
          <h1 className="BikeBulkContentSubTitle">{features}</h1>
          <Paragraph
            className="BikeBulkContentBody"
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>
      </div>
    </div>
  )
}

export default BikeDetailsProductCard
