import React from "react"
import { Box } from "@chakra-ui/core"
import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import GatsbyImage, { FluidObject } from "gatsby-image"

export type FullWidthImageNoBleed = MaxWidthGridProps & {
  image: FluidObject
}

const FullWidthImageNoBleed: React.FC<FullWidthImageNoBleed> = ({
  image,
  ...props
}) => (
  <MaxWidthGrid {...props}>
    <Box gridColumn={["1 / 3", null, null, null, "2 / 14"]}>
      <GatsbyImage fluid={image} />
    </Box>
  </MaxWidthGrid>
)

export default FullWidthImageNoBleed
