import React from "react"
import { AspectRatioBox, Flex, FlexProps, Select } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"

import Body from "../typography/Body"
import MaxWidthGrid from "../Layout/MaxWidthGrid"
import BackgroundImage from "../BackgroundImage"
import { useIsSideBarOpen } from "../../hooks/useIsSidebarOpen"
import { bpWithSidebar } from "../../utils/MediaQueries"

export interface BikeHeightSelectorProps {
  specs: Array<{ label: string; value: string }>
  images: Array<{ image: FluidObject; label: string }>
}

const BikeHeightSelctor: React.FC<BikeHeightSelectorProps & FlexProps> = ({
  specs,
  images,
  ...props
}) => {
  if (specs.length === 0 || images.length === 0) return null
  const isSideBarOpen = useIsSideBarOpen()
  const [selected, setSelected] = React.useState<string>(images[0].label)

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props}>
      <Flex
        w="100%"
        maxW={bpWithSidebar("37.5rem", "100%", isSideBarOpen)}
        gridColumn={bpWithSidebar("1 / 3", "3/13", isSideBarOpen)}
        flexDir={bpWithSidebar("column", "row", isSideBarOpen)}
        align="flex-start"
        justify="center"
        mx="auto"
      >
        <Flex
          p={bpWithSidebar("1.25rem", "2rem 2.25rem", isSideBarOpen)}
          borderRadius="0.5rem"
          boxShadow="0px 1.5625rem 6.25rem rgba(18, 25, 35, 0.05);"
          // h={bpWithSidebar("22.5rem", "33.75rem", isSideBarOpen)}
          h="auto"
          w={bpWithSidebar("100%", "50%", isSideBarOpen)}
          flexDir="column"
        >
          <Select
            onChange={(value) => {
              setSelected(value.currentTarget.value)
            }}
            borderColor="dividerLine"
          >
            {images.map(({ label }) => (
              <option
                value={label}
                key={`bike-image-selector-options-${label}`}
              >
                {label}
              </option>
            ))}
          </Select>
          {images.map(({ image, label }) =>
            label === selected ? (
              <AspectRatioBox
                key={`bike-selector-images-${label}`}
                ratio={1226 / 1055}
                width="100%"
                maxH="100%"
                mt="1rem"
              >
                <BackgroundImage
                  fluid={image}
                  backgroundSize="contain"
                  h="100%"
                  w="100%"
                />
              </AspectRatioBox>
            ) : null
          )}
        </Flex>
        <Flex
          flexDir="column"
          w={bpWithSidebar("100%", "45%", isSideBarOpen)}
          ml={bpWithSidebar(null, "5%", isSideBarOpen)}
          mt={bpWithSidebar("2.5rem", "0", isSideBarOpen)}
        >
          {specs
            .filter((spec) => spec.label && spec.value)
            .map((spec) => (
              <Flex
                key={`bike-height-selector-specs-${spec.label}-${spec.value}`}
                w="100%"
                p="0.5rem 0"
              >
                <Body
                  color="dusk"
                  w={bpWithSidebar("25%", "20%", isSideBarOpen)}
                  flexGrow={1}
                  textTransform="capitalize"
                >
                  {(spec.label || "").toLowerCase()}
                </Body>
                <Body
                  color="dawn"
                  w={bpWithSidebar("25%", "20%", isSideBarOpen)}
                  ml="5%"
                  flexGrow={1}
                >
                  {spec.value}
                </Body>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </MaxWidthGrid>
  )
}

export default BikeHeightSelctor
