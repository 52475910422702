import React from "react"
import { Flex, FlexProps, Box, SimpleGrid } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { FluidObject } from "gatsby-image"
import Image from "gatsby-image"
import { bp } from "../../utils/MediaQueries"

type ImageBlockProps = FlexProps & {
  images: FluidObject[]
}

const StyledImage = styled(Image)``

export default function ImageBlock({ images, ...props }: ImageBlockProps) {
  return (
    <Flex
      direction={bp("column", "row")}
      alignItems="center"
      justifyContent="space-around"
      backgroundColor="white"
      boxShadow="big"
      borderRadius="0.5rem"
      px="1.25rem"
      pt="1.75rem"
      pb="1.375rem"
      {...props}
    >
      {images?.map((image, index) => (
        <Box key={index} width="6.25rem" mx="1.25rem">
          <StyledImage fluid={image} />
        </Box>
      ))}
    </Flex>
  )
}
