import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import Figure from "../Figure"

type FigureSectionProps = FlexProps & {
  figures: { figure: string; figureTitle: string; description: string }[]
}

export default function FigureSection({
  figures,
  ...props
}: FigureSectionProps) {
  return (
    <Flex justify="space-around" {...props}>
      {figures.map((figure, index) => (
        <Figure key={index} {...figure} w="18.5rem" maxWidth="100%" />
      ))}
    </Flex>
  )
}
