import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../../styles/css/RelatedStoriesBlock.css"

const BlogRelatedStories = ({ allSelectedRelatedBlogIds, className }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlogPost {
        edges {
          node {
            title
            slug
            postImage {
              file {
                url
              }
            }
            contentful_id
          }
        }
      }
    }
  `)

  let selectedBlogs = data?.allContentfulBlogPost?.edges?.filter((edge) =>
    allSelectedRelatedBlogIds.includes(edge.node.contentful_id)
  )

  return (
    <div className={className}>
      <div className="relatedStoriesWrapper">
        <div className="relatedStoriesContainer">
          <h1 className="relatedStoriesHeading">Related Stories</h1>
          <ul className="relatedStoriesList">
            {selectedBlogs?.map((blog, index) => (
              <Link
                to={"/blogs/bike-advice/" + blog?.node?.slug}
                key={index}
                className="relatedStoriesItem"
              >
                <div className="relatedStoryTitleContainer">
                  <h1 className="relatedStoryTitle"> {blog?.node?.title} </h1>
                </div>
                <div className="relatedStoryImageContainer">
                  <img
                    width="100px"
                    src={blog?.node?.postImage?.file?.url}
                    alt=""
                    className="relatedStoryImg"
                  />
                </div>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BlogRelatedStories
