import React from "react"
import { Flex, Box } from "@chakra-ui/core"
import chunk from "lodash/chunk"

import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Body from "../typography/Body"
import {
  LaptopDownOnly,
  LaptopUpOnly,
  bpWithSidebar,
} from "../../utils/MediaQueries"
import Pagination from "../Pagination"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

export type QuestionsAndAnswersProps = MaxWidthGridProps & {
  list: Array<{ question: string; answer: string }>
}

const QuestionsAndAnswers: React.FC<QuestionsAndAnswersProps> = ({
  list: _list,
  ...props
}) => {
  const { isSideBarOpen } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
  }))

  const list = _list.filter((qa) => qa.question && qa.answer)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const onPrevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1)
  }
  const onNextPage = () => {
    if (pageIndex + 1 < Math.ceil(list.length / 5)) setPageIndex(pageIndex + 1)
  }

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props}>
      <Box
        gridColumn={bpWithSidebar("1 / 3", "5 / 11", isSideBarOpen)}
        overflowX="hidden"
      >
        {list.length === 0 && (
          <Body textAlign="center" color="dawn">
            No questions available
          </Body>
        )}
        {list.length > 0 && (
          <>
            <Flex
              minH={bpWithSidebar("28.75rem", "31.25rem", isSideBarOpen)}
              transform={`translateX(${
                pageIndex === 0 ? 0 : `-${pageIndex * 100}%`
              })`}
              transition="transform 0.5s"
            >
              {chunk(list, 5).map((subList, index) => (
                <Flex
                  key={index}
                  w="100%"
                  flexDirection="column"
                  flexShrink={0}
                >
                  {subList.map((item, questionIndex) => (
                    <React.Fragment key={questionIndex}>
                      <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
                        <Body fontWeight="bold" color="night" size="sm">
                          {item?.question}
                        </Body>
                        <Body
                          color="dawn"
                          mt="0.5rem"
                          size="sm"
                          whiteSpace="normal"
                          lineHeight="1.375rem"
                        >
                          {item?.answer}
                        </Body>
                      </LaptopDownOnly>
                      <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
                        <Body fontWeight="bold" color="night">
                          {item?.question}
                        </Body>
                        <Body color="dawn" mt="0.5rem">
                          {item?.answer}
                        </Body>
                      </LaptopUpOnly>
                      {questionIndex !== subList.length - 1 && (
                        <Box
                          h="1px"
                          bg="dividerLine"
                          w="100%"
                          m={bpWithSidebar(
                            "1.125rem 0",
                            "1.375rem 0",
                            isSideBarOpen
                          )}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Flex>
              ))}
            </Flex>
            <Pagination
              page={pageIndex + 1}
              pageCount={Math.ceil(list.length / 5)}
              onNext={onNextPage}
              onPrevious={onPrevPage}
              mt="1.875rem"
            />
          </>
        )}
      </Box>
    </MaxWidthGrid>
  )
}

export default QuestionsAndAnswers
