import React from "react"
import { Box, BoxProps, Flex, FlexProps, Icon } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import SmallCaps from "../typography/SmallCaps"
import Body from "../typography/Body"
import { bp, bpWithSidebar } from "../../utils/MediaQueries"
import BackgroundImage from "../BackgroundImage"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

type GeometrySpecListProps = BoxProps & {
  image: FluidObject
  note: string
  specs: {
    label: string
    details: string
    value: string
  }[]
}

const LegendDot = ({ children, ...props }: FlexProps) => (
  <Flex
    backgroundColor="dusk"
    borderRadius="1000px"
    w="1.25rem"
    h="1.25rem"
    align="center"
    justify="center"
    {...props}
  >
    <SmallCaps
      size="sm"
      color="white"
      fontWeight="bold"
      width={bp("0.4063rem", "0.4688rem")}
      height="0.75rem"
      lineHeight="0.75rem"
      textAlign="center"
    >
      {children}
    </SmallCaps>
  </Flex>
)

export default function GeometrySpecList({
  note,
  specs,
  image,
  ...props
}: GeometrySpecListProps) {
  const { isSideBarOpen } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
  }))

  return (
    <Box w="100%" {...props}>
      <Flex
        direction={bpWithSidebar("column", "row", isSideBarOpen)}
        align="center"
        justify="center"
      >
        <BackgroundImage
          maxW={bpWithSidebar("100%", "43.2281rem", isSideBarOpen)}
          w={bpWithSidebar("100%", "auto", isSideBarOpen)}
          h={bpWithSidebar("21rem", "27.6875rem", isSideBarOpen)}
          flexGrow={1}
          mr={bpWithSidebar("0", "5.625rem", isSideBarOpen)}
          mb={bpWithSidebar("2.3125rem", "0", isSideBarOpen)}
          px={bpWithSidebar("1.25rem", "0", isSideBarOpen)}
          pb={bpWithSidebar("2.3125rem", "0", isSideBarOpen)}
          borderBottomWidth={bpWithSidebar("1px", "0px", isSideBarOpen)}
          borderColor="dividerLine"
          backgroundPosition={bpWithSidebar(
            "center",
            "center right",
            isSideBarOpen
          )}
          backgroundSize="contain"
          fluid={image}
        />
        <Box>
          <Flex mb="2.0863rem">
            <Icon
              name="exclamationCircle"
              size="1.25rem"
              color="dawn"
              mr="0.9156rem"
            />
            <Body color="dawn">{note}</Body>
          </Flex>
          {specs?.map(({ label, details, value }, index) => (
            <Flex key={index} mb="1rem">
              <LegendDot mr="0.7281rem">{label}</LegendDot>
              <Body color="dusk" flexGrow={1} mr="0.7281rem">
                {details}
              </Body>
              <Body color="dawn">{value}</Body>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}
