import React from "react"
import R from "ramda"
import { FluidObject } from "gatsby-image"
import { BikeListings } from "contentful-layout-field"
import { Grid, GridProps } from "@chakra-ui/core"
import { useSelector } from "react-redux"

import { BaseRootState } from "../../redux/store"
import { placeholderImage } from "../../utils"
import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { BikeType } from "../../redux/types/BikeTypes"
import BikeProductCard from "../BikeProductCard"
import BikeDetailsProductCard, { BikeVariant } from "../BikeDetailsProductCard"
import { mapURL } from "../../utils/url"

type BikeListingProps = GridProps & { bikeEntries: BikeListings["bikes"] }

export default function BikeDetails({
  bikeEntries,
  ...props
}: BikeListingProps) {
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)
  const { bikes: allBikes } = useSelector((state: BaseRootState) => state.bikes)

  const bikeModels = (bikeEntries
    .map((bikeEntry) =>
      allBikes.find(
        (bikeModel) => bikeModel.id === bikeEntry?.value?.entry?.entryId
      )
    )
    .filter((bike) => !!bike) as BikeType[]).map((bikeModel) => ({
    ...bikeModel,
    variants: R.flatten(
      bikeModel?.speeds?.map(
        (speed) =>
          speed?.variants?.map((variant) => ({
            uniqueId: variant?.shopifyId || "",
            productHandle: speed.internalTitle || "",
            speed: speed.speed || "",
            color: {
              internalTitle: variant?.color?.internalTitle || "",
              hexCode: variant?.color?.hexCode || "#FFF",
              name: variant?.color?.name || "-",
            },
            image: (variant?.productListingImage?.fluid ||
              placeholderImage) as FluidObject,
          })) || []
      ) || []
    ),
  }))

  const pricing = useShopifyVariantPricing(
    R.flatten(
      bikeModels.map((bikeModel) =>
        bikeModel.variants.map((variant) => variant.uniqueId)
      )
    )
  )

  const bikes = bikeModels.map(({ title, internalTitle, variants }) => ({
    id: internalTitle,
    title: title || "",
    to: mapURL("bike-page", internalTitle),
    variants: variants.map(
      (variant) =>
        ({
          ...variant,
          pricing: pricing.pricingMap[variant.uniqueId],
        } as BikeVariant)
    ),
    showCompare: false,
  }))

  return (
    <Grid
      gridTemplateColumns="repeat( auto-fit, minmax(400px, 1fr) )"
      gridColumn={["1/end", null, null, null, "2/14", "1/end"]}
      marginX={[null, null, null, null, null, "1.25rem"]}
      alignItems="center"
      justifyContent="center"
      gridGap="1rem"
      {...props}
    >
      {bikes.map(({ id, ...bike }) => (
        <BikeDetailsProductCard key={id} {...bike} />
      ))}
    </Grid>
  )
}
