import React from "react"
import { Box, Flex } from "@chakra-ui/core"

const Header: React.FC<{
  number: number
  title: string
  active?: boolean
  rightComponent?: JSX.Element
}> = ({ number, title, rightComponent }) => (
  <Flex
    h="5.9531rem"
    w="100%"
    align="center"
    fontFamily="din"
    justifyContent="space-between"
  >
    <Flex>
      <Flex
        className="bodyFitAccordionHeaderNumberIcon"
        h="1.5rem"
        w="1.5rem"
        bg="dusk"
        color="white"
        borderRadius="100px"
        justify="center"
        align="center"
        fontSize="0.75rem"
      >
        {number}
      </Flex>
      <Box
        ml="1rem"
        textTransform="uppercase"
        className="bodyFitAccordionHeaderTitle"
      >
        {title}
      </Box>
    </Flex>
    {rightComponent && <Flex>{rightComponent}</Flex>}
  </Flex>
)

export default Header
