import React from "react"
import { Box } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Paragraph from "../typography/Paragraph"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"
import { AssetValue } from "contentful-layout-field"
import styled from "@emotion/styled"

export type TwoThirdsTextWithImageProps = MaxWidthGridProps & {
  image: AssetValue & {
    asset: FluidObject
  }
  text: string
  heading?: string
}

const StyledImage = styled(BackgroundImage)<{ backgroundSize: string }>`
  background-size: ${(props) => props.backgroundSize} !important;
  overflow: hidden;
`

const TwoThirdsTextWithImage: React.FC<TwoThirdsTextWithImageProps> = ({
  image,
  text,
  heading,
  ...props
}) => (
  <MaxWidthGrid {...props}>
    <Box
      gridColumn={["1 / 3", null, null, null, "3 / 7"]}
      paddingRight={[null, null, null, null, "12.5%"]}
    >
      <LaptopDownOnly>
        <StyledImage
          fluid={image.asset}
          style={{ minHeight: "23.125rem", borderRadius: "0.5rem" }}
          backgroundSize={image?.options?.backgroundSize ?? "cover"}
        />
      </LaptopDownOnly>
      <LaptopUpOnly>
        <StyledImage
          fluid={image.asset}
          style={{ minHeight: "31.25rem", borderRadius: "0.5rem" }}
          backgroundSize={image?.options?.backgroundSize ?? "cover"}
        />
      </LaptopUpOnly>
    </Box>
    <Box
      gridColumn={["1 / 3", null, null, null, "7 / 13"]}
      mt={["3.625rem", null, null, null, "0"]}
    >
      {heading !== undefined && (
        <Paragraph
          size="xl"
          dangerouslySetInnerHTML={{ __html: heading }}
          color="night"
          mb={["2.5rem", null, null, null, "5rem"]}
        />
      )}
      <Paragraph
        size="md"
        dangerouslySetInnerHTML={{ __html: text }}
        color="dusk"
      />
    </Box>
  </MaxWidthGrid>
)

export default TwoThirdsTextWithImage
