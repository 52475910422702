import React from "react"
import { Box, BoxProps, Link, SimpleGrid } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import GatsbyImage from "gatsby-background-image"
import styled from "@emotion/styled"

type ImageGridProps = BoxProps & {
  images: {
    image: FluidObject
    url?: string
  }[]
}

const StyledImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
`

const ImageBlock = ({ image }: { image: FluidObject }) => (
  <Box height="7.5rem" textAlign="center">
    <StyledImage fluid={image} />
  </Box>
)

export default function ImageGrid({ images, ...props }: ImageGridProps) {
  return (
    <Box mb="5rem" {...props}>
      <SimpleGrid columns={3} spacing="7.5rem">
        {images?.map(({ image, url }, index) =>
          url ? (
            <Link key={index} href={url}>
              <ImageBlock key={index} image={image} />
            </Link>
          ) : (
            <ImageBlock key={index} image={image} />
          )
        )}
      </SimpleGrid>
    </Box>
  )
}
