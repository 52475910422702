import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import SelectField from "../FormFields/SelectField"
import ZendeskForm from "../ZendeskForm"
import OrderCancellationForm from "./forms/OrderCancellationForm"
import ShippingAddressChangeForm from "./forms/ShippingAddressChangeForm"
import ItemStockInquiryForm from "./forms/ItemStockInquiryForm"
import OrderTrackingForm from "./forms/OrderTrackingForm"
import WrongBikeDeliveredForm from "./forms/WrongBikeDeliveredForm"
import MissingItemForm from "./forms/MissingItemForm"
import ReturnsForm from "./forms/ReturnsForm"
import { StringParam, useQueryParam } from "use-query-params"

type ContactFormsProps = BoxProps & {}

const FORMS = {
  "order-cancellation": {
    label: "Order Cancellation",
    component: OrderCancellationForm,
  },
  "shipping-address": {
    label: "Shipping Address Change",
    component: ShippingAddressChangeForm,
  },
  "item-inquiry": {
    label: "Item Stock Inquiry",
    component: ItemStockInquiryForm,
  },
  // "order-tracking": {
  //   label: "Order Tracking Information",
  //   component: OrderTrackingForm,
  // },
  "wrong-bike": {
    label: "Wrong Bike Delivered",
    component: WrongBikeDeliveredForm,
  },
  "missing-item": {
    label: "Missing Item",
    component: MissingItemForm,
  },
  returns: {
    label: "Returns",
    component: ReturnsForm,
  },
}

const FORM_OPTIONS = Object.entries(FORMS).map(([key, { label }]) => (
  <option key={key} value={key}>
    {label}
  </option>
))

export default function ContactForms({ ...props }: ContactFormsProps) {
  const [topic, setTopic] = useQueryParam("form", StringParam)
  const Form = topic ? FORMS[topic].component : Box
  const subject = topic ? FORMS[topic].label : "N/A"

  const handleChangeTopic = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setTopic(value)
  }
  return (
    <Box {...props}>
      <SelectField
        placeholder="Choose a topic"
        onChange={handleChangeTopic}
        mb="1.25rem"
      >
        {FORM_OPTIONS}
      </SelectField>
      <Form />
    </Box>
  )
}
