import React, { ReactNode } from "react"
import { Box, BoxProps, PseudoBox, Flex } from "@chakra-ui/core"
import { Link } from "gatsby"
import Heading from "../typography/Heading"
import Body from "../typography/Body"
import FadingSlideshow, { FadingSlideshowProps } from "../FadingSlideshow"
import { Button, RainbowButton } from "../Buttons"
import { WebLink } from "contentful-layout-field"
import { bp } from "../../utils/MediaQueries"

export type HeroProps = BoxProps & {
  heading?: ReactNode
  subtitle?: String
  images: FadingSlideshowProps["sources"]
  links?: WebLink[]
}

const Hero = ({
  heading,
  subtitle,
  images,
  children,
  links = [],
  ...props
}: HeroProps) => {
  return (
    <Box
      pt={heading ? bp("6vh", "12vh") : "0"}
      pb={bp(`${6 + links.length * 1.5}rem`, "6rem")}
      textAlign="center"
      {...props}
    >
      {heading && (
        <Box px="2rem" pb="6vh">
          <Heading mb="1.75rem">{heading}</Heading>
          <Body size="md" color="dawn" mb="2rem">
            {subtitle}
          </Body>
        </Box>
      )}
      <FadingSlideshow
        h={["60vh", null, null, "80vh", "85vh"]}
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        duration={5000}
        sources={images}
      >
        <Flex
          pos="absolute"
          top="calc(100% + 1rem)"
          direction={bp("column", "row")}
          align="center"
          justify="center"
        >
          {links.map(({ url, label, color }, index) => (
            <PseudoBox
              as={Link}
              key={index}
              w={bp("100%", "auto")}
              mr={bp(0, "1rem")}
              mb={bp("1rem", 0)}
              // @ts-ignore
              to={url}
            >
              {color === "rainbow" ? (
                <RainbowButton w={bp("100%", "auto")}>{label}</RainbowButton>
              ) : (
                <Button theme={color} w={bp("100%", "auto")}>
                  {label}
                </Button>
              )}
            </PseudoBox>
          ))}
        </Flex>
      </FadingSlideshow>
    </Box>
  )
}

export default Hero
