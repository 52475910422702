import React from "react"
import { Box, Flex, Icon } from "@chakra-ui/core"
import ListItem, { ListItemProps } from "./ListItem"
import Notice from "../Notice"

type SelectListItemProps = ListItemProps & {
  preorderLabel?: string
}

const SelectListItem = ({
  children,
  preorderLabel,
  ...props
}: SelectListItemProps) => (
  <ListItem px="0.125rem" cursor="pointer" {...props}>
    <Flex px="0.3125rem" minH="4rem" align="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
      >
        {children}
      </Box>
      <Icon color="#eee" name="chevron-right" ml="0.9325rem" />
    </Flex>
    {preorderLabel && (
      <Notice size="sm" mb="0.625rem">
        {preorderLabel}
      </Notice>
    )}
  </ListItem>
)

export default SelectListItem
