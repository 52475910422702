import React, { useState, useRef } from "react"
import {
  Flex,
  Input,
  Box,
  useDisclosure,
  PseudoBox,
  BoxProps,
} from "@chakra-ui/core"
import { useSelector, useDispatch } from "react-redux"
import NumberFormat from "react-number-format"
import { isValidPhoneNumber } from "libphonenumber-js"

import { Button } from "./Buttons"
import { DesktopQuestionStepper } from "./QuestionStepper"
import Body from "../typography/Body"

import * as QuizTypes from "../../redux/types/QuizTypes"
import { BaseRootState, BaseDispatch } from "../../redux/store"
import { LaptopUpOnly, LaptopDownOnly, bp } from "../../utils/MediaQueries"
import { unformatPhoneNumber } from "../../utils/text"

const QuestionText: React.FC = ({ children }) => (
  <Body
    size="lg"
    fontWeight="bold"
    color="#fff"
    mb={["1.25rem", null, null, null, "2.5rem"]}
    className="QuizQuestionText"
  >
    {children}
  </Body>
)

export interface QuestionProps {
  questionText: string
  currentQuestion: boolean
  options?: Array<QuizTypes.OptionProps>
  onAnswer: (answerId: string | Array<string>) => void
  answerId?: string
}

export const Question: React.FC<QuestionProps> = ({
  questionText,
  currentQuestion,
  options,
  onAnswer,
  answerId,
}) =>
  currentQuestion ? (
    // Question Option Buttons
    <Flex
      flexDir="column"
      mt={bp(0, 0)}
      w="100%"
      className="buttonFlex"
      justifyContent="center"
      alignItems="center"
    >
      <QuestionText>{questionText}</QuestionText>
      {options?.map((opt) => (
        <Box
          display="inline"
          key={`${questionText}-question-opt-${opt.answerId}`}
          w="100%"
        >
          <LaptopDownOnly>
            <Button
              className="QuizButtonWhite"
              mb="0.75rem"
              theme="secondary"
              h="4rem"
              onClick={() => onAnswer(opt.answerId)}
              w="100%"
            >
              {opt.answerText}
            </Button>
          </LaptopDownOnly>
          <LaptopUpOnly>
            <Button
              className="QuizQuestionAnswerBtn"
              mb="1.25rem"
              w="100%"
              h="3rem"
              onClick={() => onAnswer(opt.answerId)}
              bg={answerId === opt.answerId ? "seafoam" : undefined}
            >
              {opt.answerText}
            </Button>
          </LaptopUpOnly>
        </Box>
      ))}
    </Flex>
  ) : (
    <></>
  )

export const NumberQuestion: React.FC<
  QuestionProps & { isEditing?: boolean }
> = ({ questionText, currentQuestion, options, onAnswer, isEditing }) => {
  const [answers, setAnswers] = React.useState<Array<string>>(
    options?.map((_) => "") || []
  )

  const haveAllQuestionsBeenAnswered = () =>
    answers.reduce((acc, cur) => {
      if (cur.length < 1) return false
      return acc
    }, true)
  return currentQuestion ? (
    <Flex flexDir="column" mt={bp("1.5rem", 0)}>
      <QuestionText>{questionText}</QuestionText>
      <Flex flexDir={[null, null, null, null, "column"]}>
        <Flex>
          {options?.map((opt, index) => (
            <Input
              className="QuizQuestionInput"
              key={`question-opt${opt.answerId}`}
              placeholder={opt.answerText}
              type="number"
              h="3.5rem"
              width="3.5rem"
              p="0"
              borderRadius="0.5rem"
              borderColor="dividerLine"
              color="dawn"
              fontSize="body15"
              lineHeight="1.25rem"
              letterSpacing="-0.15px"
              textAlign="center"
              mr={["0.75rem", null, null, null, "1rem"]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAnswers(
                  answers.map((a, i) => {
                    if (i === index) {
                      const value = parseInt(event.target.value)
                      if (value !== NaN) {
                        return `${value}`
                      }
                    }
                    return a
                  })
                )
              }
            />
          ))}
        </Flex>
        <Button
          className="QuizQuestionButton extraControls"
          isDisabled={!haveAllQuestionsBeenAnswered()}
          theme={!haveAllQuestionsBeenAnswered() ? "secondary" : "primary"}
          ml={["1.25rem", null, null, null, "0"]}
          mt={[null, null, null, null, "2rem"]}
          w="100%"
          onClick={() => onAnswer(answers)}
          h="3.5rem"
          style={{
            width: "300px",
          }}
        >
          {isEditing !== undefined ? "save" : "next"}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export const GenderQuestion = (props: QuestionProps) => (
  <Question
    {...props}
    options={[
      {
        answerText: "Womens",
        answerId: "Womens",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
      {
        answerText: "Mens",
        answerId: "Mens",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
      {
        answerText: "Either",
        answerId: "Either",
        type: "option",
        riderType: [],
        filterBikes: true,
      },
    ]}
  />
)

export const EmailQuestion: React.FC<
  Omit<QuestionProps, "options"> & {
    isEditing?: boolean
    defaultValue?: string | null
  }
> = ({ currentQuestion, questionText, onAnswer, isEditing, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "")
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isValid = inputRef.current
    ? !!inputRef.current.validity.valid
    : !!defaultValue
  return currentQuestion ? (
    <Flex flexDir="column" mt={bp(0, 0)}>
      <QuestionText>{questionText}</QuestionText>
      <Flex flexDir="column">
        <Flex>
          <Input
            ref={inputRef}
            placeholder="Email"
            type="email"
            value={value}
            h="3.5rem"
            borderRadius="0.5rem"
            borderColor="dividerLine"
            color="night"
            fontSize="body15"
            lineHeight="1.25rem"
            letterSpacing="-0.15px"
            isRequired
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
          />
        </Flex>
        <Button
          className="QuizButtonWhite"
          isDisabled={!isValid}
          theme={!isValid ? "secondary" : "primary"}
          mt="1rem"
          w="100%"
          onClick={() => onAnswer(value)}
          h="3.5rem"
        >
          {isEditing !== undefined ? "Save" : "Next"}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export const PhoneQuestion: React.FC<
  Omit<QuestionProps, "options"> & { isEditing?: boolean }
> = ({ currentQuestion, questionText, onAnswer, isEditing }) => {
  const [value, setValue] = useState("")
  const unformatted = unformatPhoneNumber(value)
  const isValid = isValidPhoneNumber(`+1${unformatted}`, "US")

  return currentQuestion ? (
    <Flex flexDir="column" mt={bp(0, 0)}>
      <QuestionText>{questionText}</QuestionText>
      <Flex flexDir="column">
        <Flex>
          <Input
            as={NumberFormat}
            name={name}
            type="tel"
            h="3.5rem"
            // @ts-ignore
            format="(###) ###-####"
            allowEmptyFormatting
            removeFormatting={unformatPhoneNumber}
            mask="_"
            value={value}
            customInput={Input}
            onValueChange={(values) => {
              setValue(values.value)
            }}
          />
        </Flex>
        <Button
          isDisabled={!isValid}
          className="QuizButtonWhite"
          theme={!isValid ? "secondary" : "primary"}
          mt="1rem"
          w="100%"
          onClick={() => onAnswer(value)}
          h="3.5rem"
        >
          {isEditing !== undefined ? "Save" : "Next"}
        </Button>
        {!isValid && unformatted.length === 10 && (
          <Body color="sunrise" mt="0.5rem">
            This phone number is not valid in the US
          </Body>
        )}
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

type QuizQuestionsProps = {
  defaultEmail?: string
  className?: string
  onExit?: VoidFunction
}

const QuizQuestions: React.FC<QuizQuestionsProps> = ({
  defaultEmail = null,
  className,
  onExit,
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const {
    questions,
    quizProgressState,
    currentQuestionIndex,
    answers,
  } = useSelector(({ quiz }: BaseRootState) => quiz)
  const confirmExit = useDisclosure()

  const onStepNext = () => {
    dispatch.quiz.nextQuestion()
  }
  const onStepPrev = () => {
    dispatch.quiz.prevQuestion()
  }

  const interactiveProps: BoxProps = {
    opacity: confirmExit.isOpen ? 0.2 : 1,
    transition: "opacity 300ms ease",
    pointerEvents: confirmExit.isOpen ? "none" : "auto",
  }

  return (
    <Flex
      className={className}
      position="relative"
      flexDir="column"
      height="100%"
      pt={["2.5rem", null, null, null, "3rem"]}
      pb={["2.5rem", null, null, null, "0.5rem"]}
      justifyContent={[null, null, null, null, "space-between"]}
      mx={bp("1.25rem", 0)}
    >
      <PseudoBox {...interactiveProps}>
        {questions.map((question, index) => {
          const questionProps = {
            ...question,
            currentQuestion: currentQuestionIndex === index,
            onAnswer: (answerId: string | Array<string>) => {
              dispatch.quiz.answerQuestion({
                questionId: question.questionId,
                answerId,
              })
              dispatch.quiz.nextQuestion()
              question.onAnswer?.(answerId)
            },
          }
          if (question.questionId === "email") {
            return (
              <EmailQuestion
                key={`question-${question.questionId}`}
                defaultValue={defaultEmail}
                {...questionProps}
              />
            )
          }
          if (question.questionId === "phone") {
            return (
              <PhoneQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          if (
            question.questionId === "height" ||
            question.questionId === "weight"
          ) {
            return (
              <NumberQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          if (question.questionId === "gender") {
            return (
              <GenderQuestion
                key={`question-${question.questionId}`}
                {...questionProps}
              />
            )
          }
          return (
            <Question
              key={`question-${question.questionId}`}
              {...questionProps}
            />
          )
        })}
      </PseudoBox>
      <Box mt="0.75rem">
        <DesktopQuestionStepper
          className="BodyQuizQuestionPagination"
          color="#fff"
          currentQuestionNumber={currentQuestionIndex + 1}
          totalQuestionNumber={questions.length}
          canGoToNextQuestion={
            answers[questions[currentQuestionIndex]?.questionId] !== undefined
          }
          quizProgressState={quizProgressState}
          onNext={onStepNext}
          onPrev={onStepPrev}
          {...interactiveProps}
          mt="1rem"
        />

        <Box display={bp("block", "block")} mt="1rem">
          {confirmExit.isOpen && (
            <>
              <Body mb="1rem" color="#fff">
                Are you sure you want to exit?
              </Body>
              <Flex flexDir={bp("column", "row")}>
                <Button
                  className="QuizOutlineBtn"
                  flexGrow={1}
                  onClick={() => {
                    confirmExit.onClose()
                    onExit?.()
                  }}
                  mr="0.5rem"
                >
                  Yes
                </Button>
                <Button
                  flexGrow={1}
                  theme="secondary"
                  onClick={confirmExit.onClose}
                  mt={bp("15px", 0)}
                >
                  No
                </Button>
              </Flex>
            </>
          )}
          {!confirmExit.isOpen && (
            <Button
              className="BodyFitQuizExitBtn"
              w="100%"
              theme="tertiary"
              color="#fff"
              mt="0.75rem"
              onClick={confirmExit.onOpen}
            >
              Exit
            </Button>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default QuizQuestions
