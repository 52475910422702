import React from "react"
import { Flex, FlexProps } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import Body from "../typography/Body"
import { Button } from "../Buttons"
import ExternalLink from "../ExternalLink"
import CenteredImage from "../CenteredImage"

type AssemblyBlockProps = FlexProps & {
  image: FluidObject
  label: string
  pdfUrl?: string
  videoEmbed?: string
  onVideoClick?: (video: string) => void
}

const commonButtonProps = {
  py: "0",
  px: "1rem",
  h: "2rem",
}

export default function AssemblyBlock({
  image,
  label,
  pdfUrl,
  videoEmbed,
  onVideoClick,
  ...props
}: AssemblyBlockProps) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="1.7488rem"
      {...props}
    >
      <CenteredImage
        w="100%"
        h="13.125rem"
        borderRadius="0.5rem"
        mb="1.6906rem"
        fluid={image}
      />
      <Body fontWeight="bold" color="night" mb="0.7994rem">
        {label}
      </Body>
      <Flex>
        {pdfUrl ? (
          <ExternalLink href={pdfUrl}>
            <Button theme="secondary" {...commonButtonProps}>
              PDF
            </Button>
          </ExternalLink>
        ) : (
          <Button theme="secondary" isDisabled {...commonButtonProps}>
            PDF
          </Button>
        )}
        <Button
          theme="primary"
          isDisabled={!videoEmbed}
          ml="0.6875rem"
          onClick={() => onVideoClick?.(videoEmbed!)}
          {...commonButtonProps}
        >
          Video
        </Button>
      </Flex>
    </Flex>
  )
}
