import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"

type FormBoxProps = BoxProps & {}

export default function FormBox({ ...props }: FormBoxProps) {
  return (
    <Box
      as="form"
      px="2.5rem"
      py="2.9869rem"
      boxShadow="big"
      borderRadius="0.5rem"
      {...props}
    ></Box>
  )
}
