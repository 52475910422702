import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import NewsletterForm from "../NewsletterForm"
import Body from "../typography/Body"
import { subscribeNewsletter } from "../../api/subscribeNewsletter"

type KlaviyoProps = BoxProps & {
  listId: string
}

export default function Klaviyo({ listId, ...props }: KlaviyoProps) {
  const [status, setStatus] = React.useState<
    "initial" | "success" | "error" | "loading"
  >("initial")

  const handleSubmit = async ({ email }: { email: string }) => {
    setStatus("loading")
    try {
      await subscribeNewsletter({ email, listId })
      setStatus("success")
    } catch {
      setStatus("error")
    }
  }

  return (
    <Box textAlign="center" {...props}>
      <NewsletterForm
        onSubmit={handleSubmit}
        isLoading={status === "loading"}
      />
      {status === "success" && (
        <Body mt="1rem" color="primary">
          Thank you for subscribing!
          <br />
          Check your email to confirm your subscription
        </Body>
      )}
      {status === "error" && (
        <Body mt="1rem" color="sunrise">
          Unable to subscribe. Please try again later
        </Body>
      )}
    </Box>
  )
}
