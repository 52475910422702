import { Box, Flex, PseudoBoxProps, Text, usePrevious } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import React from "react"

import isString from "lodash/isString"
import useJudgeMeReviews from "../../hooks/useJudgeMeReviews"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { decodeShopifyId, placeholderImage } from "../../utils"
import { FitType } from "../BikeProductPage/QuickFit"
import { getFixedPrice } from "../Cart/utils"
import { ColorType } from "../ColorPicker"
import { OptionsProps } from "../DropDownSelector"
import FormattedTitle from "../FormattedTitle"
import SlashedText from "../SlashedText"
import Body, { BodyProps } from "../typography/Body"

const BikeName: React.FC<{ children: string; onClick: VoidFunction }> = ({
  children,
  onClick,
}) => (
  <Text
    fontFamily="din"
    fontSize={["1.25rem", null, null, null, "1.375rem"]}
    letterSpacing="0.0313rem"
    color="night"
    lineHeight={["1.4375rem", null, null, null, "1.625rem"]}
    onClick={onClick}
  >
    <FormattedTitle raw={children} />
  </Text>
)

const DescriptionText: React.FC<BodyProps> = ({ children, ...props }) => (
  <Body
    lineHeight={["1.25rem", null, null, null, "1.375rem"]}
    color="dawn"
    mr={["0.5rem", null, null, null, "0"]}
    {...props}
  >
    {children}
  </Body>
)

const BikeImageFluid = styled(GatsbyImage)`
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center !important;
  justify-content: flex-end;
  div:first-of-type {
    padding-bottom: 70% !important;
  }
`

const BikeImageString = styled(Flex)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  justify-content: flex-end;
  padding-top: 70%;
`

export interface BikeVariant {
  uniqueId: string
  productHandle: string
  speed: string
  color: { internalTitle: string; hexCode: string; name: string }
  isNew: boolean
  forSale: boolean
  image?: FluidObject | string
  pricing?: ShopifyVariantPricing
}

export type BaseBikeProductCardProps = {
  title: string
  variants?: Array<BikeVariant>
  to?: string
  matchPercent?: number // 98 = 98%
  bestMatch?: boolean // Makes match badge a different color
  fitStatus?: FitType | null
  onClick?: ({ speed: string, color: ColorType, variant: BikeVariant }) => void
  onSpeedChange?: (speed: string) => void
  onColorChange?: (color: ColorType) => void
  showCompare?: boolean
  onCompare?: (variant: BikeVariant) => void
  view?: "normal" | "toggle"
  selected?: boolean
}

export type BikeProductCardProps = Omit<PseudoBoxProps, "onClick"> &
  BaseBikeProductCardProps

const BikeDetailsProductCard: React.FC<BikeProductCardProps> = ({
  title,
  matchPercent,
  onClick,
  to,
  bestMatch = false,
  fitStatus,
  onSpeedChange = (speed: string) => {},
  onColorChange = (color: ColorType) => {},
  variants,
  showCompare,
  onCompare,
  view = "normal",
  selected = false,
  ...props
}) => {
  const filteredVariants = variants?.filter(
    ({ color, speed }) => color !== null && speed !== null
  )
  if (!filteredVariants || filteredVariants.length === 0) return null

  const [currentShopifyId, setCurrentShopifyId] = React.useState<string>(
    filteredVariants[0].uniqueId
  )
  const [currentSpeed, setCurrentSpeed] = React.useState<string>(
    filteredVariants[0].speed
  )
  const [currentColor, setCurrentColor] = React.useState<string>(
    filteredVariants[0].color?.name
  )
  const currentVariant = filteredVariants.find(
    (variant) => variant.uniqueId === currentShopifyId
  )
  const pricing = currentVariant?.pricing
  const currentPrice = getFixedPrice(pricing?.priceV2.amount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing?.compareAtPriceV2.amount)
    : null
  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock
  const noticeText =
    !pricing || isAvailable
      ? null
      : isPreorder
      ? "(Preorder)"
      : "(Out of Stock)"
  const previousSpeed = usePrevious(currentSpeed)
  const previousColor = usePrevious(currentColor)
  const productHandle = currentVariant?.productHandle
  const previousProductHandle = usePrevious(productHandle)
  const reviews = useJudgeMeReviews(productHandle, {
    lazy: true,
    minimal: true,
  })

  const colorOptions = (filteredVariants?.reduce((acc, cur) => {
    if (
      acc.find(
        (color) =>
          color.colorId === cur.color?.name ||
          color.colorCode === cur.color?.hexCode
      )
    )
      return acc
    return [...acc, { colorId: cur.color?.name, colorCode: cur.color?.hexCode }]
  }, [] as Array<ColorType>) ?? []) as Array<ColorType>

  const speedOptions = (
    filteredVariants?.reduce((acc, cur, index) => {
      if (acc.find(({ name }) => name === cur.speed)) {
        return acc
      }
      return [
        ...acc,
        { title: `${cur.speed} speed`, name: cur.speed, selected: false },
      ]
    }, []) ?? []
  )
    .sort((a, b) => {
      const aSpeed = parseInt(a.name)
      const bSpeed = parseInt(b.name)
      return aSpeed > bSpeed ? 1 : aSpeed < bSpeed ? -1 : 0
    })
    .map((speed, index) => ({
      ...speed,
      selected: speed.name === currentSpeed,
    })) as Array<OptionsProps>

  const onCurrentColorChange = (color: ColorType) => {
    setCurrentColor(color.colorId)
    onColorChange(color)
  }

  const onCurrentSpeedChange = (speed: string) => {
    setCurrentSpeed(speed)
    onSpeedChange(speed)
  }

  const handleClick = () => {
    onClick?.({
      speed: currentSpeed,
      color: currentColor,
      variant: currentVariant,
    })
  }

  const handleMouseEnter = () => {
    // Fetch reviews on demand
    if (
      view !== "toggle" &&
      !reviews.fetched &&
      currentVariant?.productHandle
    ) {
      reviews.getJudgemeData()
    }
  }
  // Fetch review product/speed was changed
  React.useEffect(() => {
    if (
      !reviews.fetched &&
      !reviews.isLoading &&
      previousProductHandle &&
      productHandle &&
      previousProductHandle !== productHandle
    ) {
      reviews.getJudgemeData()
    }
  }, [reviews.fetched, reviews.isLoading, productHandle, previousProductHandle])

  // Sync default speed/color variants
  React.useEffect(() => {
    const newVariant = filteredVariants.find(
      (variant) =>
        variant.speed === currentSpeed && variant.color?.name === currentColor
    )

    if (newVariant) {
      setCurrentShopifyId(newVariant.uniqueId)
    } else {
      // There isn't a variant with the new speed-color combo
      // Find a default variant to set:
      if (currentSpeed !== previousSpeed) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.speed === currentSpeed
        )
        if (defaultVariant) {
          onCurrentColorChange({
            colorId: defaultVariant.color.name,
            colorCode: defaultVariant.color.hexCode,
          })
        }
      } else if (currentColor !== previousColor) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.color.name === currentColor
        )
        if (defaultVariant) {
          onCurrentSpeedChange(defaultVariant.speed)
        }
      } else {
        setCurrentShopifyId(filteredVariants[0].uniqueId)
      }
    }
  }, [filteredVariants, currentColor, currentSpeed])

  return (
    <Box
      as={to && view !== "toggle" ? Link : "div"}
      // @ts-ignore
      to={`${to}/?variant=${decodeShopifyId(currentShopifyId)}`}
      mx={["0", null, null, null, "0.9375rem"]}
      // h={["14.5rem", null, null, null, "25rem"]}
      onClick={handleClick}
      className="BikeBoxLink"
    >
      <Flex w="100%" flexDirection="column" className="BikeDetailsWrapper">
        {isString(currentVariant?.image) ? (
          <BikeImageString
            className="BikeDetailImageContaciner"
            backgroundImage={`url(${
              currentVariant?.image ?? placeholderImage.src
            })`}
          />
        ) : (
          <BikeImageFluid
            className="BikeDetailImageContaciner"
            fluid={currentVariant?.image ?? placeholderImage}
            backgroundColor="white"
            fadeIn
            imgStyle={{
              height: "auto",
              objectFit: "contain",
            }}
          />
        )}

        <Flex
          flexDir="row"
          flexGrow={1}
          pb="0.5rem"
          justifyContent="space-between"
          className="BikeInfoContainer"
        >
          <h1 className="BikeTitle" onClick={handleClick}>
            {title}
          </h1>
          <Flex as="span">
            <span className="BikePrice">{currentPrice}</span>
            {compareAtPrice && (
              <SlashedText as="span" ml="0.5rem">
                {compareAtPrice}
              </SlashedText>
            )}
          </Flex>
        </Flex>

        <Flex w="100%">
          <h1 className="BikeSubTitle">
            Aluminum frame | Seven-speed | Hand brakes
          </h1>
        </Flex>
      </Flex>
    </Box>
  )
}

export default BikeDetailsProductCard
