import React from "react"
import {
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  IDrawer,
  Flex,
  Icon,
} from "@chakra-ui/core"
import SmallCaps from "../typography/SmallCaps"
import { Button } from "../Buttons"
import { bp } from "../../utils/MediaQueries"

type CustomizerDrawerProps = IDrawer & {
  title: string
  showNext?: boolean
  onNextClick: VoidFunction
}

const CustomizerDrawer = ({
  title,
  showNext,
  onNextClick,
  children,
  onClose,
  ...props
}: CustomizerDrawerProps) => {
  return (
    <Drawer isFullHeight onClose={onClose} {...props}>
      <DrawerContent display="flex" boxShadow="big" className="BodyFitDrawer">
        <DrawerHeader
          px="2.25rem"
          pt={bp("2rem", "2.5rem")}
          pb={bp("1.5rem", "2.5rem")}
          borderBottomWidth="1px"
          borderColor="dividerLine"
          display="flex"
          className="BodyFitDrawerHeader"
        >
          <Flex onClick={onClose} cursor="pointer">
            <Icon color="dusk" name="chevron-left" mr="0.6875rem" />
            <SmallCaps color="dusk" className="BodyFitDrawerHeaderTitle">
              {title}
            </SmallCaps>
          </Flex>
        </DrawerHeader>
        <DrawerBody flexGrow={1} overflowY="auto">
          {children}
        </DrawerBody>
        <DrawerFooter
          className="BodyFitDrawerFooter"
          pt={bp("2rem", "2.71rem")}
          pb={bp("2rem", "5.79rem")}
          px="2.4075rem"
          borderTopWidth="1px"
          borderColor="dividerLine"
          display="flex"
          flexDirection="column"
        >
          {showNext && (
            <Button
              className="QuizButtonWhite"
              w="100%"
              theme="primary"
              onClick={onNextClick}
              mb="1.25rem"
              bg="#fff"
              // color="#586067"
            >
              Next
            </Button>
          )}
          <Button
            className="QuizOutlineBtn"
            w="100%"
            theme={showNext ? "tertiary" : "primary"}
            onClick={onClose}
          >
            Done
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CustomizerDrawer
