import { Grid, GridProps } from "@chakra-ui/core"
import { BikeListings } from "contentful-layout-field"
import GatsbyImage, { FluidObject } from "gatsby-image"
import R from "ramda"
import React from "react"
import { useSelector } from "react-redux"
import { AssetValue, Value } from "contentful-layout-field"
import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { BaseRootState } from "../../redux/store"
import { BikeType } from "../../redux/types/BikeTypes"
import { placeholderImage } from "../../utils"
import { bp } from "../../utils/MediaQueries"
import { mapURL } from "../../utils/url"
import RelatedBikeCard, { BikeVariant } from "./RelatedBikeCard"

type BikeListingProps = GridProps & {
  bikeEntries: BikeListings["bikes"]
  imageMap: any
}

export default function RelatedBikes({
  bikeEntries,
  imageMap,
  ...props
}: BikeListingProps) {
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)
  const { bikes: allBikes } = useSelector((state: BaseRootState) => state.bikes)

  const bikeModels = (
    bikeEntries
      .map((bikeEntry) =>
        allBikes.find(
          (bikeModel) => bikeModel.id === bikeEntry?.value?.entry?.entryId
        )
      )
      .filter((bike) => !!bike) as BikeType[]
  ).map((bikeModel) => ({
    ...bikeModel,
    variants: R.flatten(
      bikeModel?.speeds?.map(
        (speed) =>
          speed?.variants?.map((variant) => ({
            uniqueId: variant?.shopifyId || "",
            productHandle: speed.internalTitle || "",
            speed: speed.speed || "",
            color: {
              internalTitle: variant?.color?.internalTitle || "",
              hexCode: variant?.color?.hexCode || "#FFF",
              name: variant?.color?.name || "-",
            },
            image: (variant?.productListingImage?.fluid ||
              placeholderImage) as FluidObject,
          })) || []
      ) || []
    ),
  }))

  const pricing = useShopifyVariantPricing(
    R.flatten(
      bikeModels.map((bikeModel) =>
        bikeModel.variants.map((variant) => variant.uniqueId)
      )
    )
  )

  const bikes = bikeModels.map(({ title, internalTitle, variants }) => ({
    id: internalTitle,
    title: title || "",
    to: mapURL("bike-page", internalTitle),
    variants: variants.map(
      (variant) =>
        ({
          ...variant,
          pricing: pricing.pricingMap[variant.uniqueId],
        } as BikeVariant)
    ),
    showCompare: false,
  }))

  const selectedBikeData = []

  bikeEntries.forEach((item) => {
    const { entryId } = item?.value?.entry
    const filteredBikes = allBikes.find(
      // @ts-ignore
      (shoppifyItem) => shoppifyItem.id === entryId
    )
    if (filteredBikes) {
      // @ts-ignore
      selectedBikeData.push({
        ...filteredBikes,
        //@ts-ignore
        variants: R.flatten(
          filteredBikes?.speeds?.map(
            (speed) =>
              speed?.variants?.map((variant) => ({
                uniqueId: variant?.shopifyId || "",
                productHandle: speed.internalTitle || "",
                speed: speed.speed || "",
                color: {
                  internalTitle: variant?.color?.internalTitle || "",
                  hexCode: variant?.color?.hexCode || "#FFF",
                  name: variant?.color?.name || "-",
                },
                image: (variant?.productListingImage?.fluid ||
                  placeholderImage) as FluidObject,
              })) || []
          ) || []
        ),
        // @ts-ignore
        logo: item?.value?.logo,
        // @ts-ignore
        score: item?.value?.score,
        // @ts-ignore
        displayCustomInfo: item?.value?.displayCustomInfo,
        // @ts-ignore
        customBikeImg: item?.value?.customBikeImg,
        // @ts-ignore
        customBikeTitle: item?.value?.customBikeTitle,
        // @ts-ignore
        customBikePrice: item?.value?.customBikePrice,
        // @ts-ignore
        customReviewsCount: item?.value?.customReviewsCount,
        // @ts-ignore
        customBikeURL: item?.value?.customBikeURL,
      })
    }
  })

  const selectedBikes = selectedBikeData.map(
    ({
      title,
      internalTitle,
      variants,
      score,
      logo,
      displayCustomInfo,
      customBikeImg,
      customBikeTitle,
      customBikePrice,
      customReviewsCount,
      customBikeURL,
    }) => ({
      id: internalTitle,
      title: title || "",
      to: mapURL("bike-page", internalTitle),
      //@ts-ignore
      variants: variants.map(
        (variant) =>
          ({
            ...variant,
            pricing: pricing.pricingMap[variant.uniqueId],
          } as BikeVariant)
      ),
      showCompare: false,
      score: score,
      logo: logo,
      displayCustomInfo: displayCustomInfo,
      customBikeImg: customBikeImg,
      customBikeTitle: customBikeTitle,
      customBikePrice: customBikePrice,
      customReviewsCount: customReviewsCount,
      customBikeURL: customBikeURL,
    })
  )

  return (
    <Grid
      gridTemplateColumns={bp(
        "repeat(auto-fit,minmax(100%,1fr))!important",
        "repeat(auto-fit,minmax(100%,1fr))!important"
      )}
      gridColumn={["1/end", null, null, null, "2/14", "1/end"]}
      marginX={[null, null, null, null, null, "auto"]}
      alignItems="center"
      justifyContent="center"
      maxWidth={"50rem"}
      style={{ borderTop: "1px solid #ccc" }}
      className="relatedBikeCards"
      {...props}
    >
      {selectedBikes.length &&
        selectedBikes.map(({ id, ...selectedBike }, key) => (
          <RelatedBikeCard
            imageMap={imageMap}
            index={key}
            key={id}
            {...selectedBike}
          />
        ))}
    </Grid>
  )
}
