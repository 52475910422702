import React from "react"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import { Box, BoxProps } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"

const Image = styled(BackgroundImage)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
`

type CenteredImageProps = BoxProps & {
  fluid: FluidObject
  fadeIn?: boolean
  rootMargin?: string
}

const CenteredImage: React.FC<CenteredImageProps> = React.forwardRef(
  function CenteredImage(
    { fluid, children, fadeIn = true, rootMargin, ...props },
    ref
  ) {
    return (
      <Box {...props} ref={ref}>
        {children}
        <Image fluid={fluid} fadeIn={fadeIn} rootMargin={rootMargin} />
      </Box>
    )
  }
)

export default CenteredImage
