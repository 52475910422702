import React from "react"
import { Flex, Box, BoxProps } from "@chakra-ui/core"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"
import Heading from "../typography/Heading"
import CustomizerOptions from "./CustomizerOptions"
import FormattedTitle from "../FormattedTitle"

type CustomizerSidebarProps = BoxProps & {}

export default function CustomizerSidebar({
  ...props
}: CustomizerSidebarProps) {
  const {
    bikes: { customizerBike },
  } = useSelector((state: BaseRootState) => state)
  return (
    <Box h="100%" px="2.5rem" {...props}>
      <Flex h="100%" flexDir="column" justify="center">
        <Heading size="4" mb="3.375rem">
          <FormattedTitle
            color="#fff"
            raw={customizerBike?.bike.formattedTitle || ""}
          />
        </Heading>
        <CustomizerOptions />
      </Flex>
    </Box>
  )
}
