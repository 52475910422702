import React from "react"
import { Flex, Box } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import CenteredImage from "../CenteredImage"
import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Body from "../typography/Body"
import {
  LaptopUpOnly,
  LaptopDownOnly,
  bpWithSidebar,
} from "../../utils/MediaQueries"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

export interface SelectProps {
  label: string
  selected: boolean
  onSelect: VoidFunction
  isSideBarOpen: boolean
}

const Select: React.FC<SelectProps> = ({
  label,
  selected,
  onSelect,
  isSideBarOpen,
}) => (
  <>
    <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
      <Flex
        p="0.5rem 1rem"
        bg={selected ? "sunrise" : "none"}
        borderRadius="0.5rem"
        border="1px solid"
        borderColor="dividerLine"
        cursor="pointer"
        onClick={onSelect}
        flexShrink={0}
        mr="0.5rem"
      >
        <Body color={selected ? "white" : "dusk"} size="small">
          {label}
        </Body>
      </Flex>
    </LaptopDownOnly>
    <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
      <Flex m="0.5rem 0" cursor="pointer" onClick={onSelect}>
        <Flex
          bg={selected ? "sunrise" : "noon"}
          h="1.25rem"
          w="1.25rem"
          justifyContent="center"
          alignItems="center"
          borderRadius="1.25rem"
          mr="0.75rem"
        >
          <Box
            bg={selected ? "white" : ""}
            h="0.5rem"
            w="0.5rem"
            borderRadius="0.5rem"
          />
        </Flex>
        <Body
          color={selected ? "sunrise" : "dusk"}
          fontWeight={selected ? "medium" : "normal"}
        >
          {label}
        </Body>
      </Flex>
    </LaptopUpOnly>
  </>
)

export type BikeSelectorProps = MaxWidthGridProps & {
  pairs: Array<{ image: FluidObject; label: string }>
}

const BikeSelector: React.FC<BikeSelectorProps> = ({ pairs, ...props }) => {
  const { isSideBarOpen } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
  }))

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0)
  if (pairs.length < 1) return null

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props}>
      <Box gridColumn={bpWithSidebar("1 / 3", "3 / 9", isSideBarOpen)}>
        <CenteredImage
          fluid={pairs[selectedIndex].image}
          h={bpWithSidebar("13.4375rem", "27.625rem", isSideBarOpen)}
        />
      </Box>
      <Box
        gridColumn={bpWithSidebar("1 / 3", "10 / 12", isSideBarOpen)}
        display={bpWithSidebar("flex", "initial", isSideBarOpen)}
        mt={bpWithSidebar("0.75rem", "0", isSideBarOpen)}
        overflowX="auto"
      >
        {pairs.map((pair, index) => (
          <Select
            key={index}
            label={pair.label}
            selected={index === selectedIndex}
            onSelect={() => {
              if (selectedIndex !== index) setSelectedIndex(index)
            }}
            isSideBarOpen={isSideBarOpen}
          />
        ))}
      </Box>
    </MaxWidthGrid>
  )
}
export default BikeSelector
