import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { FormProvider } from "react-hook-form"
import FormBox from "../common/FormBox"
import FormTitle from "../common/FormTitle"
import FormSubtitle from "../common/FormSubtitle"
import FormDescription from "../common/FormDescription"
import FormFieldsLayout from "../common/FormFieldsLayout"
import { ConnectedTextField as TextField } from "../../FormFields/TextField"
import { ConnectedTextAreaField as TextAreaField } from "../../FormFields/TextAreaField"
import { Button } from "../../Buttons"
import Body from "../../typography/Body"
import config from "../../../config"
import useGoogleForm from "../../../hooks/useGoogleForm"
import SuccessWrapper from "./SuccessWrapper"

type ItemStockInquiryFormProps = BoxProps & {}

type FormValues = {
  name: string
  email: string
  bikes: string
  comments: string
}

export default function ItemStockInquiryForm({
  ...props
}: ItemStockInquiryFormProps) {
  const { methods, onSubmit, isSubmitting, success, error } = useGoogleForm<
    FormValues
  >({
    sheetsId: config.GATSBY_ITEM_STOCK_GOOGLE_SHEETS_ID,
  })

  return (
    <FormProvider {...methods}>
      <FormBox {...props} onSubmit={methods.handleSubmit(onSubmit)}>
        <SuccessWrapper success={success}>
          <FormTitle>Item Stock Inquiry Request</FormTitle>
          <FormDescription>
            Is the bike you want out of stock? We&apos;re happy to help you get
            an estimated return window to pencil into your calendar.
            <br />
            <br />
            We have a larger amount of inquires than normal and we&apos;re
            working through the requests as quickly as possible. We appreciate
            your patience.
            <br />
            <br />
            Please complete the form below and our team will be notified
            you&apos;re in the queue to be helped.
          </FormDescription>
          <FormFieldsLayout mb="2.9531rem">
            <TextField
              name="Email Address"
              placeholder="Email address"
              type="email"
              isRequired
            />
            <TextField name="Your Name" placeholder="Your Name" isRequired />
          </FormFieldsLayout>
          <FormSubtitle mb="0.5rem">What bike(s) caught your eye?</FormSubtitle>
          <Body size="sm" color="dusk" mb="0.5rem">
            Please enter bike name(s) or SKU(s) below:
          </Body>
          <TextField
            name="What bike(s) caught your eye?"
            placeholder="Bike name(s) or SKU(s)"
            mb="1.0856rem"
            isRequired
          />
          <TextAreaField
            name="Additional Comments"
            placeholder="Additional comments"
          />
          <Box textAlign="center" mt="4.32rem">
            <Button theme="primary" type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </Box>
          {error && (
            <Body mt="2rem" color="sunrise">
              {error}
            </Body>
          )}
        </SuccessWrapper>
      </FormBox>
    </FormProvider>
  )
}
