import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import FadingImage, { FadingImagesProps } from "../FadingImage"
import useInterval from "../../hooks/useInterval"
import { BelowTabletOnly, LaptopUpOnly } from "../../utils/MediaQueries"

export type FadingSlideshowProps = BoxProps & {
  sources: {
    fluid: FluidObject | FluidObject[]
    backgroundSize?: string
    fluidMobile?: FluidObject | FluidObject[]
    backgroundSizeMobile?: string
  }[]
  duration?: number
  imageBackgroundPosition?: string
}

const FadingSlideshow = ({
  sources: _sources,
  duration = 3000,
  children,
  imageBackgroundPosition,
  ...props
}: FadingSlideshowProps) => {
  const [index, setIndex] = React.useState(0)

  const sources = _sources.filter(
    (source) => source.fluid || source.fluidMobile
  )

  useInterval(() => {
    setIndex((index + 1) % sources.length)
  }, duration)

  const imageProps: Partial<FadingImagesProps> = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    w: "100%",
    h: "100%",
    imageBackgroundPosition,
  }

  return (
    <Box position="relative" {...props}>
      {sources.map((source, i) => (
        <React.Fragment key={i}>
          {source.fluidMobile ? (
            <>
              <BelowTabletOnly>
                <FadingImage
                  {...imageProps}
                  source={source.fluidMobile}
                  imageBackgroundSize={source.backgroundSizeMobile || "cover"}
                  active={index === i}
                />
              </BelowTabletOnly>
              <LaptopUpOnly>
                <FadingImage
                  {...imageProps}
                  source={source.fluid}
                  imageBackgroundSize={source.backgroundSize || "cover"}
                  active={index === i}
                />
              </LaptopUpOnly>
              {children}
            </>
          ) : (
            <>
              <FadingImage
                {...imageProps}
                source={source.fluid}
                imageBackgroundSize={source.backgroundSize || "cover"}
                active={index === i}
              />
              {children}
            </>
          )}
        </React.Fragment>
      ))}
    </Box>
  )
}

export default FadingSlideshow
