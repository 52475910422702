import React from "react"
import { Box, BoxProps, Flex, SimpleGrid } from "@chakra-ui/core"
import VimeoBlock from "../VimeoBlock"
import Body from "../typography/Body"
import { bp } from "../../utils/MediaQueries"

type InstagramVideoGridProps = BoxProps & {
  videos: {
    vimeoId: string
    tag: string
  }[]
}

export default function InstagramVideoGrid({
  videos,
  ...props
}: InstagramVideoGridProps) {
  return (
    <Box {...props}>
      <SimpleGrid
        gridTemplateColumns={bp("repeat(1,0fr)", "repeat(3,0fr)")}
        alignItems="center"
        justifyContent="center"
        spacing="1.25rem"
      >
        {videos.map((video, index) => (
          <Flex key={index} flexDir="column" align="center" justify="center">
            <VimeoBlock vimeoId={video.vimeoId} />
            <Body size="md" mt="1.4375rem" color="dawn" textAlign="center">
              {video.tag}
            </Body>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  )
}
