import React from "react"
import { Box, BoxProps, Image } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import Rotation from "react-rotation"

type Image360Props = BoxProps & {
  images: FluidObject[]
}

const StyledRotation = styled(Rotation)`
  height: 80vh;
`

const StyledImage = styled(Image)`
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
`

const Image360 = ({ images, ...props }: Image360Props) => {
  return (
    <Box cursor="ew-resize" {...props}>
      <StyledRotation cycle>
        {images
          .filter((image) => !!image)
          .map((image, index) => (
            <StyledImage key={index} src={image.srcWebp || image.src} />
          ))}
      </StyledRotation>
    </Box>
  )
}

export default Image360
