import React from "react"
import { Box, SimpleGrid } from "@chakra-ui/core"
import Image, { FluidObject } from "gatsby-image"
import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"

type ImageSplitSectionProps = MaxWidthGridProps & {
  firstImage: FluidObject | FluidObject[]
  secondImage: FluidObject | FluidObject[]
}

const ImageSplitSection = ({
  firstImage,
  secondImage,
  ...props
}: ImageSplitSectionProps) => {
  return (
    <MaxWidthGrid as="section" textAlign="center" {...props}>
      <Box gridColumn={["1/3", null, null, null, "2/14"]}>
        <SimpleGrid
          spacing="3.6369rem"
          columns={[1, null, null, 2]}
          gridColumn={["1/3", null, null, null, "3/13"]}
        >
          <Box borderRadius="0.5rem" overflow="hidden">
            <Image fluid={firstImage} />
          </Box>
          <Box borderRadius="0.5rem" overflow="hidden">
            <Image fluid={secondImage} />
          </Box>
        </SimpleGrid>
      </Box>
    </MaxWidthGrid>
  )
}

export default ImageSplitSection
