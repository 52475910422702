import React from "react"
import { Link } from "gatsby"
import { Flex, FlexProps, Box } from "@chakra-ui/core"
import { WebLink } from "contentful-layout-field"
import SmallCaps from "../typography/SmallCaps"
import { Button, ButtonProps } from "../Buttons"
import { bp } from "../../utils/MediaQueries"

type CtaBarProps = FlexProps & {
  theme: "Primary" | "Secondary"
  text: string
  links: WebLink[]
}

const PrimaryAltButton = (props: ButtonProps) => {
  switch (props.theme) {
    case "primary":
      return (
        <Button
          backgroundColor="white"
          color="primary"
          _hover={{ backgroundColor: "noon" }}
          _active={{ backgroundColor: "noon" }}
          {...props}
        />
      )
    case "tertiary":
      return (
        <Button
          borderColor="white"
          color="white"
          {...props}
          _hover={{ backgroundColor: "primary100" }}
          _active={{ backgroundColor: "primary200" }}
        />
      )
    default:
      return <Button {...props} />
  }
}

export default function CtaBar({ theme, text, links, ...props }: CtaBarProps) {
  let background
  let color
  switch (theme) {
    case "Secondary":
      background = "white"
      color = "night"
      break
    case "Primary":
    default:
      background = "primary"
      color = "white"
      break
  }

  return (
    <Flex
      color={color}
      backgroundColor={background}
      boxShadow="big"
      borderRadius="0.5rem"
      px="2.0737rem"
      py="1.5rem"
      direction={bp("column", "row")}
      align="center"
      justify="space-between"
      {...props}
    >
      <SmallCaps
        size="large"
        fontWeight="bold"
        ml={bp(0, "1.25rem")}
        mb={bp("1.5rem", 0)}
      >
        {text}
      </SmallCaps>
      <Flex flex={1} direction={bp("column", "row")} justify="flex-end">
        {links?.map((link, index) => (
          <Box
            as={Link}
            key={index}
            // @ts-ignore
            to={link.url}
            ml={bp(0, "1.25rem")}
            mb={bp("1.25rem", 0)}
          >
            {theme === "Primary" && (
              <PrimaryAltButton theme={link.color} whiteSpace="pre">
                {link.label}
              </PrimaryAltButton>
            )}
            {theme !== "Primary" && (
              <Button theme={link.color} whiteSpace="pre">
                {link.label}
              </Button>
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}
