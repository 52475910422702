import React from "react"
import { Flex, FlexProps, Box, AspectRatioBox } from "@chakra-ui/core"
import styled from "@emotion/styled"

type EmbedProps = FlexProps & {
  code: string
  responsive?: boolean
}

const RegularEmbed = styled(Box)`
  iframe {
    max-width: 100%;
  }
`

const ResponsiveEmbed = styled(Box)`
  iframe {
    width: 100%;
    height: 100%;
  }
`

export default function Embed({
  code,
  responsive = false,
  ...props
}: EmbedProps) {
  return (
    <Flex maxWidth="100%" align="center" justify="center" {...props}>
      {responsive ? (
        <AspectRatioBox ratio={16 / 9} w="100%">
          <ResponsiveEmbed dangerouslySetInnerHTML={{ __html: code }} />
        </AspectRatioBox>
      ) : (
        <RegularEmbed dangerouslySetInnerHTML={{ __html: code }} />
      )}
    </Flex>
  )
}
