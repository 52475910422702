import React, { ReactNode } from "react"
import { Box, BoxProps, PseudoBox } from "@chakra-ui/core"
import { Link } from "gatsby"
import { WebLink } from "contentful-layout-field"
import Heading from "../typography/Heading"
import Body from "../typography/Body"
import { Button, RainbowButton } from "../Buttons"

type HeadingSectionProps = BoxProps & {
  heading?: ReactNode
  subtitle?: string
  links?: WebLink[]
}

const HeadingSection = ({
  heading,
  subtitle,
  links,
  ...props
}: HeadingSectionProps) => {
  return (
    <Box textAlign="center" {...props}>
      {heading && (
        <Heading as="h3" size="2" px="2rem">
          {heading}
        </Heading>
      )}
      {subtitle && (
        <Body
          w="38.125rem"
          maxWidth="100%"
          mx="auto"
          color="dawn"
          size="md"
          mt="0.625rem"
          px="1.25rem"
        >
          {subtitle}
        </Body>
      )}
      {links?.map((link, index) => (
        <PseudoBox
          key={index}
          as={Link}
          display="inline-block"
          mt="20"
          mx="1rem"
          // @ts-ignore
          to={link.url}
        >
          {link.color === "rainbow" ? (
            <RainbowButton width="auto" p="0.9688rem 2.5rem">
              {link.label}
            </RainbowButton>
          ) : (
            <Button theme={link.color} width="auto" p="0.9688rem 2.5rem">
              {link.label}
            </Button>
          )}
        </PseudoBox>
      ))}
    </Box>
  )
}

export default HeadingSection
