import React from "react"
import { Flex, Icon, Box, SimpleGrid } from "@chakra-ui/core"

import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import { bpWithSidebar } from "../../utils/MediaQueries"
import { stripHTML } from "../../utils/text"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

export type PathType = "beach" | "city" | "trails" | "path"
export interface BoxType {
  mainText: string
  subText: string
}
export interface FullBoxType {
  box: BoxType
  title: string
  body: string
}

export type WhereAndHowProps = MaxWidthGridProps & {
  pathTypes: Array<PathType>
  leftBox: FullBoxType
  rightBox: FullBoxType
}

export const FullBox: React.FC<FullBoxType & { isSideBarOpen: boolean }> = ({
  box,
  title,
  body,
  isSideBarOpen,
}) => (
  <Flex
    w={bpWithSidebar("100%", "auto", isSideBarOpen)}
    mt={bpWithSidebar("1.25rem", "0", isSideBarOpen)}
  >
    <Flex
      bg="primary"
      borderRadius="0.5rem"
      h="8.75rem"
      w="8.75rem"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      flexShrink={0}
    >
      <Heading
        size="3"
        fontWeight="bold"
        color="white"
        dangerouslySetInnerHTML={{ __html: box.mainText }}
      />
      <Body
        color="white"
        size="sm"
        fontWeight="bold"
        dangerouslySetInnerHTML={{ __html: box.subText }}
      />
    </Flex>
    <Box ml="1.625rem">
      <Body fontWeight="bold" color="night" textTransform="capitalize">
        {stripHTML(title).toLowerCase()}
      </Body>
      <Body
        w={bpWithSidebar("auto", "13.75rem", isSideBarOpen)}
        color="dawn"
        mt={bpWithSidebar("0.75rem", "1.75rem", isSideBarOpen)}
        flexGrow={bpWithSidebar(1, 0, isSideBarOpen)}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Box>
  </Flex>
)

const WhereAndHow: React.FC<WhereAndHowProps> = ({
  pathTypes,
  leftBox,
  rightBox,
  ...props
}) => {
  const { isSideBarOpen } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
  }))

  const isInPath = (value: PathType): boolean =>
    pathTypes.find((path) => path === value) !== undefined

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props}>
      <SimpleGrid
        gridColumn={bpWithSidebar("1 / 3", "3 / 13", isSideBarOpen)}
        justifyContent="space-between"
        columns={bpWithSidebar(2, 4, isSideBarOpen)}
        spacingX="1.5625rem"
        spacingY="3.125rem"
        mx={bpWithSidebar("1.25rem", "0", isSideBarOpen)}
        mb={bpWithSidebar("3rem", "6.5rem", isSideBarOpen)}
      >
        {pathTypes
          .reduce((acc, cur) => {
            if (acc.find((val) => val === cur)) {
              return acc
            }
            return [...acc, cur]
          }, [])
          .map((path) => (
            <Flex
              key={path}
              flexDir="column"
              justify="center"
              align="center"
              flexShrink={bpWithSidebar(0, 1, isSideBarOpen)}
            >
              <Icon name={path} size="4.5rem" />
              <Body fontWeight="medium" textTransform="capitalize" color="dusk">
                {path}
              </Body>
            </Flex>
          ))}
      </SimpleGrid>
      <Flex
        gridColumn={bpWithSidebar("1/3", "1 / end", isSideBarOpen)}
        flexDir={bpWithSidebar("column", "row", isSideBarOpen)}
        justify={bpWithSidebar(null, "space-evenly", isSideBarOpen)}
        align={bpWithSidebar("center", "flex-start", isSideBarOpen)}
      >
        <FullBox {...leftBox} isSideBarOpen={isSideBarOpen} />
        <FullBox {...rightBox} isSideBarOpen={isSideBarOpen} />
      </Flex>
    </MaxWidthGrid>
  )
}

export default WhereAndHow
