import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import FormBox from "../common/FormBox"
import ExternalLink from "../../ExternalLink"
import { Button } from "../../Buttons"

type ReturnsFormProps = BoxProps & {}

export default function ReturnsForm({ ...props }: ReturnsFormProps) {
  return (
    <FormBox {...props}>
      <Box textAlign="center">
        <ExternalLink href="https://sixthreezero.returnscenter.com/">
          <Button>Continue to Returns Center</Button>
        </ExternalLink>
      </Box>
    </FormBox>
  )
}
