import React from "react"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import { FluidObject } from "gatsby-image"
import { BoxProps, Box } from "@chakra-ui/core"
import Image from "gatsby-image"
import { mq } from "../../utils/MediaQueries"
import animations from "../../constants/animations"

export type FadingImagesProps = BoxProps & {
  source: FluidObject | FluidObject[]
  imageBackgroundSize?: string
  imageBackgroundPosition?: string
  active?: boolean
}

type StyledImageProps = {
  $objectFit?: string
  $objectPosition?: string
}

const StyledImage = styled(Image)((props: StyledImageProps) =>
  css(
    mq({
      width: "100%",
      height: "100%",
      img: {
        objectFit: `${props.$objectFit} !important`,
        objectPosition: `${props.$objectPosition} !important`,
      },
    })
  )
)

const FadingImage = ({
  source,
  imageBackgroundPosition = "top center",
  imageBackgroundSize = "contain",
  active,
  ...props
}: FadingImagesProps) => {
  return (
    <Box {...props}>
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        h="100%"
        w="100%"
        transition={`opacity 1s ${animations.bezier}`}
        opacity={active ? 1 : 0}
      >
        <StyledImage
          fluid={source}
          fadeIn={false}
          loading="eager"
          $objectFit={imageBackgroundSize}
          $objectPosition={imageBackgroundPosition}
        />
      </Box>
    </Box>
  )
}

export default FadingImage
