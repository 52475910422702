import React from "react"
import { Textarea, InputProps } from "@chakra-ui/core"
import { commonStyles } from "./utils"
import { useFormContext } from "react-hook-form"

type TextAreaFieldProps = InputProps<HTMLTextAreaElement> & {}

const TextAreaField = React.forwardRef(function TextAreaField(
  { isInvalid, ...props }: TextAreaFieldProps,
  ref: InputProps["ref"]
) {
  return (
    <Textarea
      isInvalid={isInvalid}
      {...commonStyles(isInvalid)}
      {...props}
      ref={ref}
    />
  )
})

export default TextAreaField

export function ConnectedTextAreaField({
  isRequired,
  ...props
}: TextAreaFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <TextAreaField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
