import React from "react"
import {
  AccordionItem,
  AccordionPanel,
  AccordionHeader,
  Icon,
  PseudoBox,
} from "@chakra-ui/core"
import styled from "@emotion/styled"
import Header from "./Header"
import animations from "../../constants/animations"
import { rem } from "../../utils"

const ACCORDION_HEADER_HEIGHT = rem(111)

const StyledAccordionItem = styled(AccordionItem)`
  .rah-static {
    flex-grow: 1;
    > div {
      height: 100%;
    }
  }
`

const SidebarContainer: React.FC<{
  active: boolean
  title: string
  position: number
  disabled?: boolean
  onHeaderClick?: VoidFunction
  rightComponent?: JSX.Element
}> = ({
  active,
  disabled = false,
  children,
  position,
  title,
  onHeaderClick,
  rightComponent,
}) => (
  <StyledAccordionItem
    className="BodyFitAccordionItem"
    isOpen={active}
    display="flex"
    flexDir="column"
    flexGrow={active ? 1 : 0}
  >
    <AccordionHeader
      className="BodyFitAccordionHeader"
      as="div"
      d="none"
      //@ts-ignore
      type={null}
      role="group"
      pos="relative"
      height={`${ACCORDION_HEADER_HEIGHT}rem`}
      disabled={!active && disabled}
      onClick={!disabled ? onHeaderClick : undefined}
      opacity={!active && disabled ? 0.5 : 1}
      overflow={!active ? "hidden" : "visible"}
      cursor={!active && !disabled ? "pointer" : "default"}
      background="transparent"
      border="0"
      _hover={{
        backgroundColor: !active ? "#F7F8FB" : "transparent",
      }}
    >
      <Header number={position} title={title} rightComponent={rightComponent} />
      {!disabled && !active && (
        <PseudoBox
          position="absolute"
          top="0"
          right="1.625rem"
          h="100%"
          d="flex"
          alignItems="center"
          justifyContent="center"
          opacity={0}
          transition={`opacity 200ms ${animations.bezier}`}
          _groupHover={{
            opacity: 1,
          }}
        >
          <Icon
            name="pencil"
            color="dusk"
            h="1.125rem"
            boxShadow="0px 0px 1.25rem 0.625rem #666"
            backgroundColor="noon"
          />
        </PseudoBox>
      )}
    </AccordionHeader>
    <AccordionPanel
      className="BodyFitQuizAccordion BodyFitLayoutAccordion"
      p="1.5rem 1rem 2.5rem 1rem"
      height="100%"
      // height={`calc(100vh - ${3 * ACCORDION_HEADER_HEIGHT}rem)`}
    >
      {children}
    </AccordionPanel>
  </StyledAccordionItem>
)

export default SidebarContainer
