import React from "react"
import { Box, BoxProps, Collapse, Radio, RadioGroup } from "@chakra-ui/core"
import FormTitle from "../common/FormTitle"
import FormSubtitle from "../common/FormSubtitle"
import FormDescription from "../common/FormDescription"
import FormBox from "../common/FormBox"
import FormFieldsLayout from "../common/FormFieldsLayout"
import { ConnectedTextField as TextField } from "../../FormFields/TextField"
import { ConnectedTextAreaField as TextAreaField } from "../../FormFields/TextAreaField"
import { ConnectedDateField as DateField } from "../../FormFields/DateField"
import StoreSelectField from "../common/StoreSelectField"
import { Button } from "../../Buttons"
import useGoogleForm from "../../../hooks/useGoogleForm"
import config from "../../../config"
import { FormProvider } from "react-hook-form"
import Body from "../../typography/Body"
import SuccessWrapper from "./SuccessWrapper"

type OrderCancellationFormProps = BoxProps & {}

export default function OrderCancellationForm({
  ...props
}: OrderCancellationFormProps) {
  const { methods, onSubmit, isSubmitting, success, error } = useGoogleForm<{}>(
    {
      sheetsId: config.GATSBY_ORDER_CANCELLATION_GOOGLE_SHEETS_ID,
    }
  )
  const cancellationType = methods.watch("What would you like to cancel?")

  return (
    <FormProvider {...methods}>
      <FormBox {...props} onSubmit={methods.handleSubmit(onSubmit)}>
        <SuccessWrapper success={success}>
          <FormTitle>Order Cancellation Request</FormTitle>
          <FormDescription>
            Interested in canceling your order? We&apos;re sorry it didn&apos;t
            work out, but we&apos;d love to help.
            <br />
            <br />
            IMPORTANT: IF YOU WANT TO CANCEL YOUR BIKE IN ORDER TO GET A
            DIFFERENT MODEL BASED ON ESTIMATED ARRIVAL DATES, PLEASE NOTE YOUR
            REQUEST WILL GO TO THE BACK OF THE QUEUE AND WE CANNOT GUARANTEE
            THAT YOU WILL RECEIVE YOUR NEW BIKE SOONER THAN THE ORIGINAL BIKE
            YOU PURCHASED.
            <br />
            <br />
            We have a larger amount of inquires than normal and we&apos;re
            working through the requests as quickly as possible. We appreciate
            your patience.
            <br />
            <br />
            Please complete the form below and our team will be notified
            you&apos;re in the queue to be helped. Once our team has taken care
            of your request, you&apos;ll be notified.
          </FormDescription>
          <FormFieldsLayout mb="2.9531rem">
            <TextField name="Name on order" placeholder="Name on Order" />
            <TextField
              name="Email Address"
              placeholder="Email address"
              type="email"
            />
            <DateField name="Order Date" placeholder="Order Date" />
            <StoreSelectField />
            <TextField name="Order Number" placeholder="Order Number" />
          </FormFieldsLayout>
          <FormSubtitle mb="1.5381rem">
            What would you like to cancel?
          </FormSubtitle>
          <RadioGroup name="What would you like to cancel?" mb="1.5381rem">
            <Radio
              ref={methods.register({ required: true })}
              value="Entire Order"
            >
              Entire Order
            </Radio>
            <Radio
              ref={methods.register({ required: true })}
              value="One or more items on your order"
            >
              One or more items on your order
            </Radio>
          </RadioGroup>
          <Collapse
            isOpen={cancellationType === "One or more items on your order"}
            mb="1.5381rem"
          >
            <FormSubtitle mb="1.5381rem">
              Please enter the item SKU(s) or item description(s) you&apos;d
              like to cancel below
            </FormSubtitle>
            <TextField
              name={`If you selected "One or more items on your order" above, please enter the item SKU(s) or item description(s) you'd like to cancel below`}
              placeholder="Item SKU(s) or Item description(s)"
            />
          </Collapse>
          <TextAreaField
            name="Additional Comments"
            placeholder="Additional comments"
          />
          <Box textAlign="center" mt="4.32rem">
            <Button theme="primary" type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </Box>
          {error && (
            <Body mt="2rem" color="sunrise">
              {error}
            </Body>
          )}
        </SuccessWrapper>
      </FormBox>
    </FormProvider>
  )
}
