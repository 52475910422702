import React from "react"
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/core"
import { bp } from "../../../utils/MediaQueries"

type FormFieldsLayoutProps = SimpleGridProps & {}

export default function FormFieldsLayout({ ...props }: FormFieldsLayoutProps) {
  return (
    <SimpleGrid
      columns={bp(1, 2)}
      spacingX="1.25rem"
      spacingY="1.0856rem"
      {...props}
    ></SimpleGrid>
  )
}
