import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"

import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import Title from "./Title"
import Paragraph from "../typography/Paragraph"
import CenteredImage from "../CenteredImage"
import animations from "../../constants/animations"
import { useInView } from "react-intersection-observer"
import { bp, bpWithSidebar } from "../../utils/MediaQueries"
import { useSelector } from "react-redux"
import { BaseRootState } from "../../redux/store"

export type ImageTitleTextSplitProps = MaxWidthGridProps & {
  subHeader: string
  header: string
  body: string
  image: FluidObject
  imagePosition?: "left" | "right"
}

const ImageTitledTextSplit: React.FC<ImageTitleTextSplitProps> = ({
  subHeader,
  header,
  body,
  image,
  imagePosition = "right",
  ...props
}) => {
  const { isSideBarOpen } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
  }))
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  })

  return (
    <MaxWidthGrid isSideBarOpen={isSideBarOpen} {...props} ref={ref}>
      <Flex
        gridColumn={bpWithSidebar("1 / 3", "4 / 13", isSideBarOpen)}
        flexDirection={bpWithSidebar(
          "column",
          imagePosition === "right" ? "row" : "row-reverse",
          isSideBarOpen
        )}
      >
        <Flex
          w={bpWithSidebar("100%", "32%", isSideBarOpen)}
          justify="center"
          flexDir="column"
        >
          <Box transform={bp("", "translateY(-30%)")}>
            <Title
              subTitle={subHeader}
              title={header}
              textAlign={bpWithSidebar("center", "left", isSideBarOpen)}
              alignItems={bpWithSidebar("center", "flex-start", isSideBarOpen)}
              mt="0"
            />
            <Paragraph
              size="md"
              color="dusk"
              textAlign={bpWithSidebar("center", "left", isSideBarOpen)}
              mt="1.875rem"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </Box>
        </Flex>
        <Box w={bpWithSidebar(null, "12.5%", isSideBarOpen)} />
        <Box
          maxH={bpWithSidebar("20.625rem", "35.5rem", isSideBarOpen)}
          w={bpWithSidebar("100%", "55.5%", isSideBarOpen)}
          mt={bpWithSidebar("2.625rem", "0", isSideBarOpen)}
          opacity={inView ? 1 : 0}
          transform={`translateY(${inView ? 0 : 300}px)`}
          transition={`transform 750ms ${animations.bezier}, opacity 500ms ${animations.bezier}`}
        >
          <CenteredImage
            fluid={image}
            height={bpWithSidebar("20.625rem", "35.5rem", isSideBarOpen)}
            fadeIn={false}
            rootMargin="-200px"
          />
        </Box>
      </Flex>
    </MaxWidthGrid>
  )
}

export default ImageTitledTextSplit
