import React from "react"
import { FlexProps, Flex } from "@chakra-ui/core"
import Body from "../typography/Body"
import QuizButton from "./QuizButton"
import { bp } from "../../utils/MediaQueries"

type QuizResultsProps = FlexProps & {
  isLoggedIn: boolean
  isSubmittingResults: boolean
  onSeeResults: VoidFunction
  onLogin: VoidFunction
}

export default function QuizResults({
  isLoggedIn,
  isSubmittingResults,
  onSeeResults,
  onLogin,
  ...props
}: QuizResultsProps) {
  return (
    <Flex
      h={bp("auto", "100%")}
      direction="column"
      justify="center"
      mt={bp("12.5rem", "0")}
      mx={bp("1.25rem", "0")}
      {...props}
    >
      {isLoggedIn ? (
        <>
          <Body fontWeight="bold" size="lg" mb="1.5131rem" color="#fff">
            You&apos;re all set!
          </Body>
          <QuizButton
            w="100%"
            theme="primary"
            onClick={onSeeResults}
            isLoading={isSubmittingResults}
          >
            See Results
          </QuizButton>
        </>
      ) : (
        <>
          <Body fontWeight="bold" size="lg" mb="1.5131rem" color="#fff">
            One last thing -<br />
            Want to save your results?
          </Body>
          <Body size="md" color="#eee" mb="2.25rem">
            Log in or create an account to save your results.
          </Body>

          <Flex
            className="BodyFitLayoutSeeResultFlex"
            direction="row"
            flexDirection="row"
            justify="space-between"
            style={{
              gap: "10px",
            }}
          >
            <QuizButton
              className="QuizButtonWhite"
              w="100%"
              theme="primary"
              onClick={onSeeResults}
              isLoading={isSubmittingResults}
              mb="1rem"
            >
              No, See Results
            </QuizButton>
            <QuizButton
              w="100%"
              theme="secondary"
              onClick={onLogin}
              className="QuizOutlineBtn"
            >
              Log in
            </QuizButton>
          </Flex>
        </>
      )}
    </Flex>
  )
}
