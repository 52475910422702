import React from "react"
import { Select, SelectProps } from "@chakra-ui/core"
import { commonStyles } from "./utils"
import { useFormContext } from "react-hook-form"

export type SelectFieldProps = SelectProps & {}

const SelectField = React.forwardRef(function SelectField(
  { isInvalid, ...props }: SelectFieldProps,
  ref: SelectProps["ref"]
) {
  return (
    <Select
      isInvalid={isInvalid}
      {...commonStyles(isInvalid)}
      {...props}
      ref={ref}
    />
  )
})

export default SelectField

export function ConnectedSelectField({
  isRequired,
  ...props
}: SelectFieldProps) {
  const { register, errors } = useFormContext()
  return (
    <SelectField
      {...props}
      ref={register({ required: isRequired })}
      isInvalid={!!errors[props.name || ""]}
    />
  )
}
