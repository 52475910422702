import React from "react"
import { PseudoBox, PseudoBoxProps } from "@chakra-ui/core"

export type ListItemProps = PseudoBoxProps & {}

const ListItem = ({ ...props }: ListItemProps) => {
  return (
    <PseudoBox
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
      p="0 0.875rem"
      _hover={{ borderRadius: "0.25rem", bg: "dividerLine" }}
      {...props}
    />
  )
}

export default ListItem
