import React, { useRef, useEffect, useState } from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import debounce from "lodash/debounce"
import { MotionFlex } from "../../utils/FramerMotion"
import { useMotionValue } from "framer-motion"

type DragSliderProps = BoxProps & { isSideBarOpen?: boolean }

export default function DragSlider({
  children,
  isSideBarOpen,
  ...props
}: DragSliderProps) {
  const parentRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)
  const x = useMotionValue(0)

  const [sliderWidth, setSliderWidth] = useState(0)
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0)

  const calcWidth = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return ref.current.clientWidth
    }
    return 0
  }

  const calcDimensions = () => {
    const parentWidth = calcWidth(parentRef)
    const childrenWidth = calcWidth(childrenRef)
    const space = sliderChildrenWidth - sliderWidth
    const xValue = x.get()
    // Clamp x value to what's in range
    if (-space > xValue) {
      x.set(-space)
    } else if (xValue > 0) {
      x.set(0)
    }
    setSliderWidth(parentWidth)
    setSliderChildrenWidth(childrenWidth)
  }

  const debouncedCalcDimensions = React.useCallback(
    debounce(() => {
      calcDimensions()
    }, 200),
    []
  )

  useEffect(() => {
    debouncedCalcDimensions()
    window.addEventListener("resize", debouncedCalcDimensions)

    return () => {
      window.removeEventListener("resize", debouncedCalcDimensions)
    }
  }, [])

  useEffect(() => {
    debouncedCalcDimensions()
  }, [isSideBarOpen])

  const availableSpace = sliderChildrenWidth - sliderWidth
  return (
    <Box overflow="hidden" w="100%" backgroundColor="dividerLine" {...props}>
      <MotionFlex
        ref={parentRef}
        drag={availableSpace > 0 ? "x" : false}
        dragConstraints={{
          right: 0,
          left: -availableSpace,
        }}
        style={{ x }}
        justify={availableSpace > 0 ? "flex-start" : "center"}
      >
        <Box d="inline-flex" ref={childrenRef}>
          {children}
        </Box>
      </MotionFlex>
    </Box>
  )
}
