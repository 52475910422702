import React from "react"
import { Box, BoxProps, Flex, Avatar, Link } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"

import Slider from "../Slider"
import Embed from "../Embed"
import Body from "../typography/Body"
import Heading from "../typography/Heading"

import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import styled from "@emotion/styled"

type SlideModalProps = Omit<BoxProps, "children"> & {
  slides: {
    avatar?: FluidObject,
    thumbnail?: FluidObject,
    author?: string,
    review?: string,
    title?: string,
    ytLink?: string,
  }[]
  slideDuration?: number
  initialIndex?: number
}


  const Slide: React.FC<{ avatar?: FluidObject; thumbnail?: FluidObject; author?: string; review?: string; title?: string; ytLink?: string; }> = ({
    avatar,
    thumbnail,
    author,
    review,
    title,
    ytLink,
  }) => {
  return (
    <Box>
      { thumbnail ? (
      <Embed
        responsive
        code={`<iframe loading="lazy" class="lazyload" srcdoc="<style>
        * {
        padding:0;
        margin:0;
        overflow:hidden
        }
        html, body {
        height:100%
        }
        img, .button.play {
        position:absolute;
        width:100%;
        top:0;
        bottom:0;
        margin:auto
        }
        .button.play {
        position: relative;
        left: 47%;
        top: 41%;
        box-sizing: border-box;
        border-width: 1em 0em 1em 2em;
        border-color: transparent transparent transparent white;
        text-shadow:0 0 0.5em black;
        background-color: transparent;
        cursor: pointer;
        }
        </style>
        <a href=`+ytLink+`><img width='560' height='315' src=`+thumbnail?.src+` alt=''><button class='button button--primary play'></button></a>
    " width="850" height="480" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-19="true">
</iframe>`}
      />
      ) : (
        <Embed
        responsive
        code={`<iframe width="560" height="315" src=`+ytLink+` title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`}
      />
      )}
      <Box mt="0.5rem">
        <Flex>
          <Flex flex='1' alignItems='center' flexWrap='wrap'>
            <Box>
              <Body fontWeight="bold" color="night" size="sm">
              {title}
              </Body>
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box>
        <Body
          color="dawn"
          mt="0.5rem"
          size="sm"
          whiteSpace="normal"
          lineHeight="1.375rem"
        >
          {review}
        </Body>
      </Box>
      <Box>
        <Flex mt="0.5rem">
          <Flex flex='1' alignItems='center' flexWrap='wrap'>
            <Avatar size="sm" name='Segun Adebayo' src={avatar?.src} />
            <Box>
              <Body fontWeight="bold" color="night" ml="0.25rem" size="sm">
                {author}
              </Body>
            </Box>
          </Flex>
        </Flex>
      </Box>
      
    </Box>
  )
}

export default function YoutubeReviews({
  slides,
  initialIndex = 0,
  slideDuration = 5000,
  ...props
}: SlideModalProps) {
  return (
    <Box>
      <Heading size="2" fontWeight="light" textAlign="center" color="night" mb="2rem">
          Expert Reviews
      </Heading>
      <LaptopUpOnly>
        <Slider
          containerProps={{
            h: "100%",
            w: "100%",
          }}
          items={slides.map((slide, index) =>
            <Slide avatar={slide.avatar}
            thumbnail={slide.thumbnail}
            author={slide.author}
            review={slide.review}
            title={slide.title}
            ytLink={slide.ytLink} />
          )}
          hideArrows = {false}
          autoPlay = {false}
          slidesToScroll = {4}
          autoPlayInterval={5000}
          loop = {false}
        >
          {({ Slides }) => (
            <>
              <Flex className="cb_review">
                {Slides}
              </Flex>
            </>
          )}
        </Slider>
      </LaptopUpOnly>
      <LaptopDownOnly>
        <Slider
          containerProps={{
            h: "100%",
            w: "100%",
          }}
          items={slides.map((slide, index) =>
            <Slide avatar={slide.avatar}
            thumbnail={slide.thumbnail}
            author={slide.author}
            review={slide.review}
            title={slide.title}
            ytLink={slide.ytLink} />
          )}
          hideArrows = {false}
          autoPlay = {false}
          slidesToScroll = {4}
          autoPlayInterval={5000}
          loop = {false}
        >
          {({ Slides }) => (
            <>
              <Flex className="cb_review">
                {Slides}
              </Flex>
            </>
          )}
        </Slider>
      </LaptopDownOnly>
    </Box>
  )
}
