import React from "react"
import { SimpleGrid, Box } from "@chakra-ui/core"
import MaxWidthGrid, { MaxWidthGridProps } from "../Layout/MaxWidthGrid"
import GridCard, { GridCardProps } from "../GridCard"

type GridCardSectionProps = MaxWidthGridProps & {
  cards: GridCardProps[]
}

const GridCardSection = ({ cards, ...props }: GridCardSectionProps) => {
  return (
    <MaxWidthGrid {...props}>
      <Box textAlign="center" gridColumn={["1/3", null, null, null, "2/14"]}>
        <SimpleGrid
          columns={[1, null, null, 2]}
          spacing={["2rem", null, null, null, "3.75rem"]}
        >
          {cards.map((card, index) => (
            <GridCard key={index} {...card} />
          ))}
        </SimpleGrid>
      </Box>
    </MaxWidthGrid>
  )
}

export default GridCardSection
