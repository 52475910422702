import React from "react"
import { Box, BoxProps, Flex } from "@chakra-ui/core"
import { FormProvider, useForm } from "react-hook-form"
import { ConnectedTextField as TextField } from "../FormFields/TextField"
import { Button } from "../Buttons"

type NewsletterFormProps = Omit<BoxProps, "onSubmit"> & {
  onSubmit: (data: FormValues) => void
  isLoading?: boolean
}

type FormValues = {
  email: string
}

export default function NewsletterForm({
  onSubmit,
  isLoading = false,
  ...props
}: NewsletterFormProps) {
  const methods = useForm<FormValues>()

  return (
    <Box as="form" {...props} onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Flex align="center" justify="center">
          <TextField
            name="email"
            placeholder="Email"
            isRequired
            w="11.25rem"
            h="3rem"
            mr="0.75rem"
          />
          <Button type="submit" h="3rem" isLoading={isLoading}>
            Join
          </Button>
        </Flex>
      </FormProvider>
    </Box>
  )
}
