import React from "react"
import { Box, BoxProps, Flex, Link } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"

import Slider from "../Slider"
import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import styled from "@emotion/styled"

type SlideModalProps = Omit<BoxProps, "children"> & {
  slides: {
    image: FluidObject
    mobileImage?: FluidObject
    linkUrl?: string
  }[]
  slideDuration?: number
  initialIndex?: number
}

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 90vh;
`

const Slide: React.FC<{ image: FluidObject; mobileImage?: FluidObject }> = ({
  image,
  mobileImage,
}) => {
  return (
    <Box pos="relative" h="100%" w="100%">
      {mobileImage ? (
        <>
          <LaptopDownOnly>
            <StyledGatsbyImage
              fluid={mobileImage}
              imgStyle={{ objectFit: "contain" }}
            />
          </LaptopDownOnly>
          <LaptopUpOnly>
            <StyledGatsbyImage
              fluid={image}
              imgStyle={{ objectFit: "contain" }}
            />
          </LaptopUpOnly>
        </>
      ) : (
        <StyledGatsbyImage fluid={image} imgStyle={{ objectFit: "contain" }} />
      )}
    </Box>
  )
}

export default function FullBleedImageSlider({
  slides,
  initialIndex = 0,
  slideDuration = 5000,
  ...props
}: SlideModalProps) {
  return (
    <Box w="100%" maxH="90vh" {...props}>
      <Slider
        containerProps={{
          h: "100%",
          w: "100%",
        }}
        items={slides.map((slide, index) =>
          slide.linkUrl && slide.linkUrl.trim() ? (
            <Link
              key={index}
              pos="relative"
              href={slide.linkUrl}
              w="100%"
              h="100%"
            >
              <Slide image={slide.image} mobileImage={slide.mobileImage} />
            </Link>
          ) : (
            <Slide
              key={index}
              image={slide.image}
              mobileImage={slide.mobileImage}
            />
          )
        )}
        hideArrows
        autoPlay
        slidesToScroll = {1}
        autoPlayInterval={slideDuration}
        loop
      >
        {({ Slides }) => (
          <>
            <Flex w="100%" h="100%">
              {Slides}
            </Flex>
          </>
        )}
      </Slider>
    </Box>
  )
}
