import React from "react"
import { Flex, FlexProps, Stack, Box } from "@chakra-ui/core"

import Heading from "../typography/Heading"
import SmallCaps from "../typography/SmallCaps"
import { Button, ButtonThemeType } from "../Buttons"
import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import FormattedTitle from "../FormattedTitle"
import { Link } from "gatsby"

export interface TitleProps {
  subTitle: string
  title: string
  body?: string
  id?: string
  buttons?: Array<{ title: string; url: string; theme: ButtonThemeType }>
}

const Title: React.FC<TitleProps & FlexProps> = ({
  subTitle,
  title,
  body,
  buttons,
  id,
  textAlign = "center",
  ...props
}) => (
  <Flex flexDir="column" alignItems="center" id={id} {...props}>
    <LaptopUpOnly>
      <Heading
        size="6"
        fontWeight="light"
        dangerouslySetInnerHTML={{ __html: subTitle }}
        mb="0.5rem"
        textAlign={textAlign}
        color="dawn"
      />
      <Heading size="2" fontWeight="light" textAlign={textAlign} color="night">
        <FormattedTitle
          raw={title}
          alignItems="center"
          justifyContent={textAlign === "center" ? "center" : undefined}
        />
      </Heading>
      {body && (
        <Body
          color="dawn"
          width="38.125rem"
          maxWidth="100%"
          textAlign="center"
          mt="1.25rem"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      {buttons && buttons.length > 0 && (
        <Stack isInline spacing="1.25rem" mt="1.25rem">
          {buttons.map((b) => (
            <Box
              key={`${b.title}-${b.url}`}
              as={Link}
              //@ts-ignore
              to={b.url}
            >
              <Button theme={b.theme} px="1.25rem">
                {b.title}
              </Button>
            </Box>
          ))}
        </Stack>
      )}
    </LaptopUpOnly>
    <LaptopDownOnly>
      <SmallCaps
        fontWeight="light"
        dangerouslySetInnerHTML={{ __html: subTitle }}
        mb="0.625rem"
        textAlign={textAlign}
        color="dawn"
      />
      <Heading size="4" fontWeight="light" textAlign={textAlign} color="night">
        <FormattedTitle
          raw={title}
          alignItems="center"
          justifyContent={textAlign === "center" ? "center" : undefined}
        />
      </Heading>
      {body && (
        <Body
          color="dawn"
          width="38.125rem"
          maxWidth="100%"
          textAlign="center"
          mt="1.25rem"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      {buttons && buttons.length > 0 && (
        <Stack isInline spacing="1.25rem" mt="1.25rem">
          {buttons.map((b) => (
            <Box
              key={`${b.title}-${b.url}`}
              as={Link}
              //@ts-ignore
              to={b.url}
            >
              <Button theme={b.theme} px="1.25rem">
                {b.title}
              </Button>
            </Box>
          ))}
        </Stack>
      )}
    </LaptopDownOnly>
  </Flex>
)

export default Title
