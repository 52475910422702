import React from "react"
import { Box, Flex, Button } from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"

import { BaseDispatch, BaseRootState } from "../../redux/store"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import useLoginDrawer from "../../context/loginDrawer"
import { isValidUserLoggedIn } from "../../utils/auth"
import QuizButton from "./QuizButton"
import useGoogleAnalytics from "../../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import ArrowDownIcon from "./ArrowDownIcon"

type InitialQuizScreenProps = {
  quizIntroText?: React.ReactNode
}

const HeaderText: React.FC = ({ children }) => (
  <Body color="#F7F9FB" size="sm" fontWeight="bold">
    {children}
  </Body>
)

const InitialQuizScreen: React.FC<InitialQuizScreenProps> = ({
  quizIntroText = (
    <>
      97% of customers that use our proprietary fitting system love their bike.
    </>
  ),
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { accessToken, tokenExpiration } = useSelector(
    (state: BaseRootState) => ({
      tokenExpiration: state.user.tokenExpiration,
      accessToken: state.user.accessToken,
      quizProgressState: state.quiz.quizProgressState,
      previousQuizAnswers: state.quiz.previousQuizAnswers,
    })
  )
  const { openLoginDrawer } = useLoginDrawer()
  const isLoggedIn = isValidUserLoggedIn({ accessToken, tokenExpiration })
  // Started Quiz Event
  const googleAnalytics = useGoogleAnalytics({
    category: "Body Fit",
    action: "click",
    shouldFireOnFirstRender: false,
  })

  // Sidebar State
  const {
    sidebar: { isOpen: sidebarOpen },
  } = useSelector((state: BaseRootState) => state)

  return (
    <>
      <LaptopDownOnly>
        <Flex
          className="QuizMobileHeader BodyFitLayoutMobileHeader"
          bg="primary"
          h="5rem"
          p="0 2rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box as="span">
            <HeaderText>
              <Flex
                className="QuizMobileHeaderText"
                justifyContent="space-between"
                flexDirection="column"
              >
                <span className="QuizMobileHeaderHeading">
                  Your{" "}
                  <span className="mobile-text-underline">PERFECT BIKE</span>{" "}
                  Starts Here
                </span>
                <span
                  className="QuizMobileHeaderSubHeading"
                  style={{ maxWidth: "100%" }}
                >
                  97% of customers that use our proprietary fitting system love
                  their bike.
                </span>
              </Flex>
            </HeaderText>
          </Box>

          <ArrowDownIcon
            style={{
              transform: "rotate(270deg)",
              width: "50px",
              height: "50px",
              filter: "invert(100%)",
            }}
          />

          <Button
            className="QuizMobileWhiteBtn"
            bg="white"
            borderRadius="0.5rem"
            color="primary"
            w="7.9375rem"
            h="3rem"
            p="0"
            boxShadow="0px 0.625rem 1.875rem rgba(0, 196, 209, 0.2)"
            fontFamily="din"
            fontWeight="bold"
            fontSize="0.875rem"
            lineHeight="1.0625rem"
            letterSpacing="1px"
            textTransform="uppercase"
            onClick={() => {
              dispatch.quiz.setProgressState("inprogress")
              dispatch.quiz.setQuizStartTime(Date.now().toString())
              googleAnalytics.fireEvent()
              sidebarOpen ? dispatch.sidebar.toggleOpen() : ""
            }}
          >
            Start Fitting
          </Button>
        </Flex>
      </LaptopDownOnly>
      <LaptopUpOnly>
        <Flex
          className="QuizMobileHeader BodyFitLayoutDesktopHeader"
          bg="primary"
          h="5rem"
          p="0 2rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box as="span">
            <HeaderText>
              <Flex
                className="QuizMobileHeaderText"
                justifyContent="space-between"
                flexDirection="column"
              >
                <span className="QuizMobileHeaderHeading">
                  Your{" "}
                  <span className="mobile-text-underline">PERFECT BIKE</span>{" "}
                  Starts Here
                </span>
                <span className="QuizMobileHeaderSubHeading">
                  97% of customers that use our proprietary fitting system love
                  their bike.
                </span>
              </Flex>
            </HeaderText>
          </Box>

          <ArrowDownIcon
            style={{
              transform: "rotate(270deg)",
              width: "40px",
              height: "50px",
              filter: "invert(100%)",
            }}
          />

          <Button
            className="QuizMobileWhiteBtn"
            bg="white"
            borderRadius="0.5rem"
            color="primary"
            w="7.9375rem"
            h="3rem"
            p="0"
            boxShadow="0px 0.625rem 1.875rem rgba(0, 196, 209, 0.2)"
            fontFamily="din"
            fontWeight="bold"
            fontSize="0.875rem"
            lineHeight="1.0625rem"
            letterSpacing="1px"
            textTransform="uppercase"
            onClick={() => {
              dispatch.quiz.setProgressState("inprogress")
              dispatch.quiz.setQuizStartTime(Date.now().toString())
              googleAnalytics.fireEvent()
              sidebarOpen ? dispatch.sidebar.toggleOpen() : ""
            }}
          >
            Start Fitting
          </Button>
        </Flex>
      </LaptopUpOnly>
    </>
  )
}

export default InitialQuizScreen
