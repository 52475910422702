import { Box, BoxProps, PseudoBox } from "@chakra-ui/core"
import { WebLink } from "contentful-layout-field"
import { Link } from "gatsby"
import React, { ReactNode } from "react"
import "../../styles/css/customCTA.css"

type CustomCTAProps = BoxProps & {
  heading?: ReactNode
  links?: WebLink[]
}

const CustomCTA = ({ heading, links, ...props }: CustomCTAProps) => {
  return (
    <Box
      textAlign="center"
      {...props}
      className="layout-block layout-block__custom-cta custom-cta-section"
    >
      <div className="custom-cta-flex">
        {links?.map((link, index) => (
          <PseudoBox
            key={index}
            as={Link}
            display="inline-block"
            mt="20"
            mx="1rem"
            // @ts-ignore
            to={link.url}
            className={
              link.color === "secondary"
                ? "custom-cta secondary-cta"
                : "custom-cta"
            }
          >
            {link.label}
          </PseudoBox>
        ))}
      </div>
    </Box>
  )
}

export default CustomCTA
