import React from "react"
import { Box, BoxProps, AspectRatioBox, PseudoBox } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import Image from "gatsby-background-image"
import { Link } from "gatsby"
import LinkButton from "../LinkButton"
import Heading from "../typography/Heading"
import Body from "../typography/Body"
import { WebLink } from "contentful-layout-field"
import animations from "../../constants/animations"

export type GridCardProps = BoxProps & {
  image: FluidObject | FluidObject[]
  imageBackgroundSize?: string
  heading: React.ReactNode
  body: React.ReactNode
  link?: WebLink
}

const GridCard = ({
  image,
  imageBackgroundSize = "100% auto",
  heading,
  body,
  link,
  ...props
}: GridCardProps) => {
  return (
    <PseudoBox
      as={link ? Link : Box}
      // @ts-ignore
      to={link?.url}
      background="white"
      boxShadow="0px 1.5625rem 6.25rem rgba(18, 25, 35, 0.05)"
      _hover={{
        boxShadow: "0px 1.5625rem 6.25rem rgba(18, 25, 35, 0.1)",
      }}
      willChange="box-shadow"
      transition={`box-shadow 300ms ${animations.bezier}`}
      borderRadius="0.5rem"
      textAlign={["center", null, null, null, "left"]}
      {...props}
    >
      <AspectRatioBox ratio={4 / 3}>
        <Image
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
            backgroundSize: imageBackgroundSize,
            backgroundPosition: "center",
          }}
          fluid={image}
        />
      </AspectRatioBox>
      <Box
        pt="2.5rem"
        pl={["1rem", null, null, null, "8.75%"]}
        pr={["1rem", null, null, null, "23%"]}
        pb="2.625rem"
      >
        <Heading size="6" fontWeight="bold" mb={20}>
          {heading}
        </Heading>
        <Body
          lineHeight={["1.25rem", null, null, null, "1.375rem"]}
          color="dawn"
          mb={20}
        >
          {body}
        </Body>
        {link && (
          <LinkButton color={link.color}>
            {link.label || "Learn More"}
          </LinkButton>
        )}
      </Box>
    </PseudoBox>
  )
}

export default GridCard
